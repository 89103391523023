<template>
  <div>
    <div class="container" align="left">
      <div class="row d-flex mt-5">
        <h3 class="mb-4">
          <strong>{{ $t('Policy_title') }}</strong>
        </h3>
        <p align="left">{{ $t('policy_desc') }}</p>

        <div :class="{'bookmark-links-kr': isLang === 0, 'bookmark-links-en': isLang === 1}">
          <a @click="goSection('01')">{{ $t('policy_sub1_title') }}</a>
          <a @click="goSection('02')">{{ $t('policy_sub2_title') }}</a>
          <a @click="goSection('03')">{{ $t('policy_sub3_title') }}</a>
          <a @click="goSection('04')">{{ $t('policy_sub4_title') }}</a>
          <a @click="goSection('05')">{{ $t('policy_sub5_title') }}</a>
          <a @click="goSection('06')">{{ $t('policy_sub6_title') }}</a>
          <a @click="goSection('07')">{{ $t('policy_sub7_title') }}</a>
          <a @click="goSection('08')">{{ $t('policy_sub8_title') }}</a>
          <a @click="goSection('09')">{{ $t('policy_sub9_title') }}</a>
          <a @click="goSection('10')">{{ $t('policy_sub10_title') }}</a>
          <a @click="goSection('11')">{{ $t('policy_sub11_title') }}</a>
          <a @click="goSection('12')">{{ $t('policy_sub12_title') }}</a>
          <a @click="goSection('13')">{{ $t('policy_sub13_title') }}</a>
          <a @click="goSection('14')">{{ $t('policy_sub14_title') }}</a>
        </div>

        <div class="container">
          <div class="row privacy-detail mb-5">
            <section ref="section-01" class="mt-5">
              <h5>
                <strong>{{ $t('policy_sub1_title') }}</strong>
              </h5>
              <p>{{ $t('policy_sub1_desc') }}</p>
              <p class="numText1">{{ $t('policy_sub1_1_title') }}</p>
              <p class="innertext">{{ $t('policy_sub1_1_desc') }}</p>

              <p class="numText1">{{ $t('policy_sub1_2_title') }}</p>
              <p class="innertext">{{ $t('policy_sub1_2_desc') }}</p>
              <p class="numText1">{{ $t('policy_sub1_3_title') }}</p>
              <p class="innertext">{{ $t('policy_sub1_3_desc') }}</p>
              <p class="numText1">{{ $t('policy_sub1_4_title') }}</p>
              <p class="innertext">{{ $t('policy_sub1_4_desc') }}</p>
            </section>
            <section ref="section-02" class="mt-5">
              <h5>
                <strong>{{ $t('policy_sub2_title') }}</strong>
              </h5>
              <p>{{ $t('policy_sub2_desc') }}</p>

              <table class="web table table-bordered">
                <tbody align="center">
                  <tr style="background-color: #f4f2eb; font-weight: bold">
                    <td width="15%">{{ $t('policy_sub2_table_tr1_td1') }}</td>
                    <td width="20%">{{ $t('policy_sub2_table_tr1_td2') }}</td>
                    <td>{{ $t('policy_sub2_table_tr1_td3') }}</td>
                    <td width="10%">{{ $t('policy_sub2_table_tr1_td4') }}</td>
                    <td width="10%">{{ $t('policy_sub2_table_tr1_td5') }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t('policy_sub2_table_tr2_td1') }}</td>
                    <td>{{ $t('policy_sub2_table_tr2_td2') }}</td>
                    <td v-html="$t('policy_sub2_table_tr2_td3')"></td>
                    <td>{{ $t('policy_sub2_table_tr2_td4') }}</td>
                    <td>{{ $t('policy_sub2_table_tr2_td5') }}</td>
                  </tr>
                  <tr>
                    <td rowspan="2" v-html="$t('policy_sub2_table_tr3_td1')"></td>
                    <td>{{ $t('policy_sub2_table_tr3_td2') }}</td>
                    <td v-html="$t('policy_sub2_table_tr3_td3')"></td>
                    <td>{{ $t('policy_sub2_table_tr3_td4') }}</td>
                    <td v-html="$t('policy_sub2_table_tr3_td5')"></td>
                  </tr>
                  <tr>
                    <td>{{ $t('policy_sub2_table_tr4_td2') }}</td>
                    <td>{{ $t('policy_sub2_table_tr4_td3') }}</td>
                    <td>{{ $t('policy_sub2_table_tr4_td4') }}</td>
                    <td v-html="$t('policy_sub2_table_tr4_td5')"></td>
                  </tr>
                  <tr>
                    <td v-html="$t('policy_sub2_table_tr5_td1')"></td>
                    <td>{{ $t('policy_sub2_table_tr5_td2') }}</td>
                    <td v-html="$t('policy_sub2_table_tr5_td3')"></td>
                    <td>{{ $t('policy_sub2_table_tr5_td4') }}</td>
                    <td v-html="$t('policy_sub2_table_tr5_td5')"></td>
                  </tr>
                  <tr>
                    <td v-html="$t('policy_sub2_table_tr6_td1')"></td>
                    <td>{{ $t('policy_sub2_table_tr6_td2') }}</td>
                    <td v-html="$t('policy_sub2_table_tr6_td3')"></td>
                    <td>{{ $t('policy_sub2_table_tr6_td4') }}</td>
                    <td v-html="$t('policy_sub2_table_tr6_td5')"></td>
                  </tr>
                  <tr>
                    <td v-html="$t('policy_sub2_table_tr7_td1')"></td>
                    <td>{{ $t('policy_sub2_table_tr7_td2') }}</td>
                    <td v-html="$t('policy_sub2_table_tr7_td3')"></td>
                    <td>{{ $t('policy_sub2_table_tr7_td4') }}</td>
                    <td>{{ $t('policy_sub2_table_tr7_td5') }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t('policy_sub2_table_tr8_td1') }}</td>
                    <td>{{ $t('policy_sub2_table_tr8_td2') }}</td>
                    <td v-html="$t('policy_sub2_table_tr8_td3')"></td>
                    <td>{{ $t('policy_sub2_table_tr8_td4') }}</td>
                    <td>{{ $t('policy_sub2_table_tr8_td5') }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t('policy_sub2_table_tr9_td1') }}</td>
                    <td>{{ $t('policy_sub2_table_tr9_td2') }}</td>
                    <td v-html="$t('policy_sub2_table_tr9_td3')"></td>
                    <td>{{ $t('policy_sub2_table_tr9_td4') }}</td>
                    <td>{{ $t('policy_sub2_table_tr9_td5') }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t('policy_sub2_table_tr10_td1') }}</td>
                    <td>{{ $t('policy_sub2_table_tr10_td2') }}</td>
                    <td v-html="$t('policy_sub2_table_tr10_td3')"></td>
                    <td>{{ $t('policy_sub2_table_tr10_td4') }}</td>
                    <td>{{ $t('policy_sub2_table_tr10_td5') }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t('policy_sub2_table_tr11_td1') }}</td>
                    <td>{{ $t('policy_sub2_table_tr11_td2') }}</td>
                    <td v-html="$t('policy_sub2_table_tr11_td3')"></td>
                    <td>{{ $t('policy_sub2_table_tr11_td4') }}</td>
                    <td>{{ $t('policy_sub2_table_tr11_td5') }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t('policy_sub2_table_tr12_td1') }}</td>
                    <td>{{ $t('policy_sub2_table_tr12_td2') }}</td>
                    <td v-html="$t('policy_sub2_table_tr12_td3')"></td>
                    <td>{{ $t('policy_sub2_table_tr12_td4') }}</td>
                    <td>{{ $t('policy_sub2_table_tr12_td5') }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t('policy_sub2_table_tr13_td1') }}</td>
                    <td>{{ $t('policy_sub2_table_tr13_td2') }}</td>
                    <td v-html="$t('policy_sub2_table_tr13_td3')"></td>
                    <td>{{ $t('policy_sub2_table_tr13_td4') }}</td>
                    <td>{{ $t('policy_sub2_table_tr13_td5') }}</td>
                  </tr>
                </tbody>
              </table>

              <table class="mobile table table-bordered">
                <tbody>
                  <tr>
                    <td colspan="3" style="background-color: #f4f2eb; font-weight: bold; text-align: center">
                      {{ $t('policy_sub2_table_tr2_td1') }}
                    </td>
                  </tr>
                  <tr>
                    <td>{{ $t('policy_sub2_table_tr1_td2') }}</td>
                    <td colspan="2">{{ $t('policy_sub2_table_tr2_td2') }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t('policy_sub2_table_tr1_td2') }}</td>
                    <td colspan="2" v-html="$t('policy_sub2_table_tr2_td3')"></td>
                  </tr>
                  <tr>
                    <td>{{ $t('policy_sub2_table_tr1_td4') }}</td>
                    <td colspan="2">{{ $t('policy_sub2_table_tr2_td4') }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t('policy_sub2_table_tr1_td5') }}</td>
                    <td colspan="2">{{ $t('policy_sub2_table_tr2_td5') }}</td>
                  </tr>
                </tbody>
                <tbody>
                  <!--- start -->
                  <tr>
                    <td
                      colspan="3"
                      style="background-color: #f4f2eb; font-weight: bold; text-align: center"
                      v-html="$t('policy_sub2_table_tr3_td1')"
                    ></td>
                  </tr>
                  <tr>
                    <td>{{ $t('policy_sub2_table_tr1_td2') }}</td>
                    <td>{{ $t('policy_sub2_table_tr3_td2') }}</td>
                    <td>{{ $t('policy_sub2_table_tr4_td2') }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t('policy_sub2_table_tr1_td3') }}</td>
                    <td v-html="$t('policy_sub2_table_tr3_td3')"></td>
                    <td>{{ $t('policy_sub2_table_tr4_td3') }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t('policy_sub2_table_tr1_td4') }}</td>
                    <td>{{ $t('policy_sub2_table_tr3_td4') }}</td>
                    <td>{{ $t('policy_sub2_table_tr4_td4') }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t('policy_sub2_table_tr1_td5') }}</td>
                    <td v-html="$t('policy_sub2_table_tr3_td5')"></td>
                    <td v-html="$t('policy_sub2_table_tr4_td5')"></td>
                  </tr>
                </tbody>
                <tbody>
                  <tr>
                    <td
                      colspan="3"
                      style="background-color: #f4f2eb; font-weight: bold; text-align: center"
                      v-html="$t('policy_sub2_table_tr5_td1')"
                    ></td>
                  </tr>
                  <tr>
                    <td>{{ $t('policy_sub2_table_tr1_td2') }}</td>
                    <td colspan="2">{{ $t('policy_sub2_table_tr5_td2') }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t('policy_sub2_table_tr1_td3') }}</td>
                    <td colspan="2" v-html="$t('policy_sub2_table_tr5_td3')"></td>
                  </tr>
                  <tr>
                    <td>{{ $t('policy_sub2_table_tr1_td4') }}</td>
                    <td colspan="2" v-html="$t('policy_sub2_table_tr3_td4')"></td>
                  </tr>
                  <tr>
                    <td>{{ $t('policy_sub2_table_tr1_td5') }}</td>
                    <td colspan="2" v-html="$t('policy_sub2_table_tr5_td5')"></td>
                  </tr>
                </tbody>
                <tbody>
                  <tr>
                    <td
                      colspan="3"
                      style="background-color: #f4f2eb; font-weight: bold; text-align: center"
                      v-html="$t('policy_sub2_table_tr6_td1')"
                    ></td>
                  </tr>
                  <tr>
                    <td>{{ $t('policy_sub2_table_tr1_td2') }}</td>
                    <td colspan="2">{{ $t('policy_sub2_table_tr6_td2') }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t('policy_sub2_table_tr1_td3') }}</td>
                    <td colspan="2" v-html="$t('policy_sub2_table_tr6_td3')"></td>
                  </tr>
                  <tr>
                    <td>{{ $t('policy_sub2_table_tr1_td4') }}</td>
                    <td colspan="2">{{ $t('policy_sub2_table_tr6_td4') }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t('policy_sub2_table_tr1_td5') }}</td>
                    <td colspan="2">{{ $t('policy_sub2_table_tr6_td5') }}</td>
                  </tr>
                </tbody>
                <tbody>
                  <tr>
                    <td
                      colspan="3"
                      style="background-color: #f4f2eb; font-weight: bold; text-align: center"
                      v-html="$t('policy_sub2_table_tr7_td1')"
                    ></td>
                  </tr>
                  <tr>
                    <td>{{ $t('policy_sub2_table_tr1_td2') }}</td>
                    <td colspan="2">{{ $t('policy_sub2_table_tr7_td2') }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t('policy_sub2_table_tr1_td3') }}</td>
                    <td colspan="2" v-html="$t('policy_sub2_table_tr7_td3')"></td>
                  </tr>
                  <tr>
                    <td>{{ $t('policy_sub2_table_tr1_td4') }}</td>
                    <td colspan="2">{{ $t('policy_sub2_table_tr7_td4') }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t('policy_sub2_table_tr1_td5') }}</td>
                    <td colspan="2">{{ $t('policy_sub2_table_tr7_td5') }}</td>
                  </tr>
                </tbody>
                <tbody>
                  <tr>
                    <td
                      colspan="3"
                      style="background-color: #f4f2eb; font-weight: bold; text-align: center"
                      v-html="$t('policy_sub2_table_tr8_td1')"
                    ></td>
                  </tr>
                  <tr>
                    <td>{{ $t('policy_sub2_table_tr1_td2') }}</td>
                    <td colspan="2" v-html="$t('policy_sub2_table_tr8_td2')"></td>
                  </tr>
                  <tr>
                    <td>{{ $t('policy_sub2_table_tr1_td3') }}</td>
                    <td colspan="2" v-html="$t('policy_sub2_table_tr8_td3')"></td>
                  </tr>
                  <tr>
                    <td>{{ $t('policy_sub2_table_tr1_td4') }}</td>
                    <td colspan="2" v-html="$t('policy_sub2_table_tr8_td4')"></td>
                  </tr>
                  <tr>
                    <td>{{ $t('policy_sub2_table_tr1_td5') }}</td>
                    <td colspan="2" v-html="$t('policy_sub2_table_tr8_td5')"></td>
                  </tr>
                </tbody>
                <tbody>
                  <tr>
                    <td
                      colspan="3"
                      style="background-color: #f4f2eb; font-weight: bold; text-align: center"
                      v-html="$t('policy_sub2_table_tr9_td1')"
                    ></td>
                  </tr>
                  <tr>
                    <td>{{ $t('policy_sub2_table_tr1_td2') }}</td>
                    <td colspan="2" v-html="$t('policy_sub2_table_tr9_td2')"></td>
                  </tr>
                  <tr>
                    <td>{{ $t('policy_sub2_table_tr1_td3') }}</td>
                    <td colspan="2" v-html="$t('policy_sub2_table_tr9_td3')"></td>
                  </tr>
                  <tr>
                    <td>{{ $t('policy_sub2_table_tr1_td4') }}</td>
                    <td colspan="2" v-html="$t('policy_sub2_table_tr9_td4')"></td>
                  </tr>
                  <tr>
                    <td>{{ $t('policy_sub2_table_tr1_td5') }}</td>
                    <td colspan="2" v-html="$t('policy_sub2_table_tr9_td5')"></td>
                  </tr>
                </tbody>
                <tbody>
                  <tr>
                    <td
                      colspan="3"
                      style="background-color: #f4f2eb; font-weight: bold; text-align: center"
                      v-html="$t('policy_sub2_table_tr10_td1')"
                    ></td>
                  </tr>
                  <tr>
                    <td>{{ $t('policy_sub2_table_tr1_td2') }}</td>
                    <td colspan="2" v-html="$t('policy_sub2_table_tr10_td2')"></td>
                  </tr>
                  <tr>
                    <td>{{ $t('policy_sub2_table_tr1_td3') }}</td>
                    <td colspan="2" v-html="$t('policy_sub2_table_tr10_td3')"></td>
                  </tr>
                  <tr>
                    <td>{{ $t('policy_sub2_table_tr1_td4') }}</td>
                    <td colspan="2" v-html="$t('policy_sub2_table_tr10_td4')"></td>
                  </tr>
                  <tr>
                    <td>{{ $t('policy_sub2_table_tr1_td5') }}</td>
                    <td colspan="2" v-html="$t('policy_sub2_table_tr10_td5')"></td>
                  </tr>
                </tbody>
                <tbody>
                  <tr>
                    <td
                      colspan="3"
                      style="background-color: #f4f2eb; font-weight: bold; text-align: center"
                      v-html="$t('policy_sub2_table_tr11_td1')"
                    ></td>
                  </tr>
                  <tr>
                    <td>{{ $t('policy_sub2_table_tr1_td2') }}</td>
                    <td colspan="2" v-html="$t('policy_sub2_table_tr11_td2')"></td>
                  </tr>
                  <tr>
                    <td>{{ $t('policy_sub2_table_tr1_td3') }}</td>
                    <td colspan="2" v-html="$t('policy_sub2_table_tr11_td3')"></td>
                  </tr>
                  <tr>
                    <td>{{ $t('policy_sub2_table_tr1_td4') }}</td>
                    <td colspan="2" v-html="$t('policy_sub2_table_tr11_td4')"></td>
                  </tr>
                  <tr>
                    <td>{{ $t('policy_sub2_table_tr1_td5') }}</td>
                    <td colspan="2" v-html="$t('policy_sub2_table_tr11_td5')"></td>
                  </tr>
                </tbody>
                <tbody>
                  <tr>
                    <td
                      colspan="3"
                      style="background-color: #f4f2eb; font-weight: bold; text-align: center"
                      v-html="$t('policy_sub2_table_tr12_td1')"
                    ></td>
                  </tr>
                  <tr>
                    <td>{{ $t('policy_sub2_table_tr1_td2') }}</td>
                    <td colspan="2" v-html="$t('policy_sub2_table_tr12_td2')"></td>
                  </tr>
                  <tr>
                    <td>{{ $t('policy_sub2_table_tr1_td3') }}</td>
                    <td colspan="2" v-html="$t('policy_sub2_table_tr12_td3')"></td>
                  </tr>
                  <tr>
                    <td>{{ $t('policy_sub2_table_tr1_td4') }}</td>
                    <td colspan="2" v-html="$t('policy_sub2_table_tr12_td4')"></td>
                  </tr>
                  <tr>
                    <td>{{ $t('policy_sub2_table_tr1_td5') }}</td>
                    <td colspan="2" v-html="$t('policy_sub2_table_tr12_td5')"></td>
                  </tr>
                </tbody>
                <tbody>
                  <tr>
                    <td
                      colspan="3"
                      style="background-color: #f4f2eb; font-weight: bold; text-align: center"
                      v-html="$t('policy_sub2_table_tr13_td1')"
                    ></td>
                  </tr>
                  <tr>
                    <td>{{ $t('policy_sub2_table_tr1_td2') }}</td>
                    <td colspan="2" v-html="$t('policy_sub2_table_tr13_td2')"></td>
                  </tr>
                  <tr>
                    <td>{{ $t('policy_sub2_table_tr1_td3') }}</td>
                    <td colspan="2" v-html="$t('policy_sub2_table_tr13_td3')"></td>
                  </tr>
                  <tr>
                    <td>{{ $t('policy_sub2_table_tr1_td4') }}</td>
                    <td colspan="2" v-html="$t('policy_sub2_table_tr13_td4')"></td>
                  </tr>
                  <tr>
                    <td>{{ $t('policy_sub2_table_tr1_td5') }}</td>
                    <td colspan="2" v-html="$t('policy_sub2_table_tr13_td5')"></td>
                  </tr>
                </tbody>
              </table>

              <p class="numText1 font-weight-normal" v-html="$t('policy_sub2_table_desc1')"></p>
            </section>
            <section ref="section-03" class="mt-5">
              <h5 class="mb-4">
                <strong>{{ $t('policy_sub3_title') }}</strong>
              </h5>
              <p class="numText1">
                {{ $t('policy_sub3_desc1') }}
              </p>
              <p class="numText1">
                {{ $t('policy_sub3_desc2') }}
              </p>

              <table class="web table table-bordered">
                <tbody align="center">
                  <tr style="background-color: #f4f2eb; font-weight: bold">
                    <td>{{ $t('policy_sub3_table_tr1_td1') }}</td>
                    <td>{{ $t('policy_sub3_table_tr1_td2') }}</td>
                    <td width="20%">{{ $t('policy_sub3_table_tr1_td3') }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t('policy_sub3_table_tr2_td1') }}</td>
                    <td rowspan="3">{{ $t('policy_sub3_table_tr2_td2') }}</td>
                    <td rowspan="2">{{ $t('policy_sub3_table_tr2_td3') }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t('policy_sub3_table_tr3_td1') }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t('policy_sub3_table_tr4_td1') }}</td>
                    <td>{{ $t('policy_sub3_table_tr4_td3') }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t('policy_sub3_table_tr5_td1') }}</td>
                    <td>{{ $t('policy_sub3_table_tr5_td2') }}</td>
                    <td>{{ $t('policy_sub3_table_tr5_td3') }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t('policy_sub3_table_tr6_td1') }}</td>
                    <td>{{ $t('policy_sub3_table_tr6_td2') }}</td>
                    <td>{{ $t('policy_sub3_table_tr6_td3') }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t('policy_sub3_table_tr7_td1') }}</td>
                    <td>{{ $t('policy_sub3_table_tr7_td2') }}</td>
                    <td>{{ $t('policy_sub3_table_tr7_td3') }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t('policy_sub3_table_tr8_td1') }}</td>
                    <td>{{ $t('policy_sub3_table_tr8_td2') }}</td>
                    <td>{{ $t('policy_sub3_table_tr8_td3') }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t('policy_sub3_table_tr9_td1') }}</td>
                    <td>{{ $t('policy_sub3_table_tr9_td2') }}</td>
                    <td>{{ $t('policy_sub3_table_tr9_td3') }}</td>
                  </tr>
                </tbody>
              </table>

              <table class="mobile table table-bordered">
                <tbody>
                  <tr>
                    <td style="background-color: #f4f2eb; font-weight: bold">{{ $t('policy_sub3_table_tr1_td1') }}</td>
                    <td>{{ $t('policy_sub3_table_tr2_td1') }}</td>
                  </tr>
                  <tr>
                    <td style="background-color: #f4f2eb; font-weight: bold">{{ $t('policy_sub3_table_tr1_td2') }}</td>
                    <td>{{ $t('policy_sub3_table_tr2_td2') }}</td>
                  </tr>
                  <tr>
                    <td style="background-color: #f4f2eb; font-weight: bold">{{ $t('policy_sub3_table_tr1_td3') }}</td>
                    <td>{{ $t('policy_sub3_table_tr2_td3') }}</td>
                  </tr>
                </tbody>
                <tbody>
                  <tr>
                    <td style="background-color: #f4f2eb; font-weight: bold">{{ $t('policy_sub3_table_tr1_td1') }}</td>
                    <td>{{ $t('policy_sub3_table_tr3_td1') }}</td>
                  </tr>
                  <tr>
                    <td style="background-color: #f4f2eb; font-weight: bold">{{ $t('policy_sub3_table_tr1_td2') }}</td>
                    <td>{{ $t('policy_sub3_table_tr2_td2') }}</td>
                  </tr>
                  <tr>
                    <td style="background-color: #f4f2eb; font-weight: bold">{{ $t('policy_sub3_table_tr1_td3') }}</td>
                    <td>{{ $t('policy_sub3_table_tr2_td3') }}</td>
                  </tr>
                </tbody>
                <tbody>
                  <tr>
                    <td style="background-color: #f4f2eb; font-weight: bold">{{ $t('policy_sub3_table_tr1_td1') }}</td>
                    <td>{{ $t('policy_sub3_table_tr4_td1') }}</td>
                  </tr>
                  <tr>
                    <td style="background-color: #f4f2eb; font-weight: bold">{{ $t('policy_sub3_table_tr1_td2') }}</td>
                    <td>{{ $t('policy_sub3_table_tr2_td2') }}</td>
                  </tr>
                  <tr>
                    <td style="background-color: #f4f2eb; font-weight: bold">{{ $t('policy_sub3_table_tr1_td3') }}</td>
                    <td>{{ $t('policy_sub3_table_tr4_td3') }}</td>
                  </tr>
                </tbody>
                <tbody>
                  <tr>
                    <td style="background-color: #f4f2eb; font-weight: bold">{{ $t('policy_sub3_table_tr1_td1') }}</td>
                    <td>{{ $t('policy_sub3_table_tr5_td1') }}</td>
                  </tr>
                  <tr>
                    <td style="background-color: #f4f2eb; font-weight: bold">{{ $t('policy_sub3_table_tr1_td2') }}</td>
                    <td>{{ $t('policy_sub3_table_tr5_td2') }}</td>
                  </tr>
                  <tr>
                    <td style="background-color: #f4f2eb; font-weight: bold">{{ $t('policy_sub3_table_tr1_td3') }}</td>
                    <td>{{ $t('policy_sub3_table_tr5_td3') }}</td>
                  </tr>
                </tbody>
                <tbody>
                  <tr>
                    <td style="background-color: #f4f2eb; font-weight: bold">{{ $t('policy_sub3_table_tr1_td1') }}</td>
                    <td>{{ $t('policy_sub3_table_tr6_td1') }}</td>
                  </tr>
                  <tr>
                    <td style="background-color: #f4f2eb; font-weight: bold">{{ $t('policy_sub3_table_tr1_td2') }}</td>
                    <td>{{ $t('policy_sub3_table_tr6_td2') }}</td>
                  </tr>
                  <tr>
                    <td style="background-color: #f4f2eb; font-weight: bold">{{ $t('policy_sub3_table_tr1_td3') }}</td>
                    <td>{{ $t('policy_sub3_table_tr6_td3') }}</td>
                  </tr>
                </tbody>
                <tbody>
                  <tr>
                    <td style="background-color: #f4f2eb; font-weight: bold">{{ $t('policy_sub3_table_tr1_td1') }}</td>
                    <td>{{ $t('policy_sub3_table_tr7_td1') }}</td>
                  </tr>
                  <tr>
                    <td style="background-color: #f4f2eb; font-weight: bold">{{ $t('policy_sub3_table_tr1_td2') }}</td>
                    <td>{{ $t('policy_sub3_table_tr7_td2') }}</td>
                  </tr>
                  <tr>
                    <td style="background-color: #f4f2eb; font-weight: bold">{{ $t('policy_sub3_table_tr1_td3') }}</td>
                    <td>{{ $t('policy_sub3_table_tr7_td3') }}</td>
                  </tr>
                </tbody>
                <tbody>
                  <tr>
                    <td style="background-color: #f4f2eb; font-weight: bold">{{ $t('policy_sub3_table_tr1_td1') }}</td>
                    <td>{{ $t('policy_sub3_table_tr8_td1') }}</td>
                  </tr>
                  <tr>
                    <td style="background-color: #f4f2eb; font-weight: bold">{{ $t('policy_sub3_table_tr1_td2') }}</td>
                    <td>{{ $t('policy_sub3_table_tr8_td2') }}</td>
                  </tr>
                  <tr>
                    <td style="background-color: #f4f2eb; font-weight: bold">{{ $t('policy_sub3_table_tr1_td3') }}</td>
                    <td>{{ $t('policy_sub3_table_tr8_td3') }}</td>
                  </tr>
                </tbody>
                <tbody>
                  <tr>
                    <td style="background-color: #f4f2eb; font-weight: bold">{{ $t('policy_sub3_table_tr1_td1') }}</td>
                    <td>{{ $t('policy_sub3_table_tr9_td1') }}</td>
                  </tr>
                  <tr>
                    <td style="background-color: #f4f2eb; font-weight: bold">{{ $t('policy_sub3_table_tr1_td2') }}</td>
                    <td>{{ $t('policy_sub3_table_tr9_td2') }}</td>
                  </tr>
                  <tr>
                    <td style="background-color: #f4f2eb; font-weight: bold">{{ $t('policy_sub3_table_tr1_td3') }}</td>
                    <td>{{ $t('policy_sub3_table_tr9_td3') }}</td>
                  </tr>
                </tbody>
              </table>
            </section>

            <section ref="section-04" class="mt-5">
              <h5 class="mb-4">
                <strong>{{ $t('policy_sub4_title') }}</strong>
              </h5>
              <p class="numText1">{{ $t('policy_sub4_desc1') }}</p>

              <p class="numText2">{{ $t('policy_sub4_desc1_1') }}</p>
              <p class="numText2">{{ $t('policy_sub4_desc1_2') }}</p>
              <p class="numText2">{{ $t('policy_sub4_desc1_3') }}</p>
              <p class="numText2">{{ $t('policy_sub4_desc1_4') }}</p>
              <p class="numText2">{{ $t('policy_sub4_desc1_5') }}</p>
              <p class="numText2">{{ $t('policy_sub4_desc1_6') }}</p>
              <p class="numText2">{{ $t('policy_sub4_desc1_7') }}</p>
              <p class="numText2">{{ $t('policy_sub4_desc1_8') }}</p>

              <p class="numText1">{{ $t('policy_sub4_desc2') }}</p>

              <p class="numText1">{{ $t('policy_sub4_desc3') }}</p>
              <table class="web table table-bordered">
                <tbody align="center">
                  <tr style="background-color: #f4f2eb; font-weight: bold">
                    <td>{{ $t('policy_sub4_table_tr1_td1') }}</td>
                    <td>{{ $t('policy_sub4_table_tr1_td2') }}</td>
                    <td>{{ $t('policy_sub4_table_tr1_td3') }}</td>
                    <td width="20%">{{ $t('policy_sub4_table_tr1_td4') }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t('policy_sub4_table_tr2_td1') }}</td>
                    <td>{{ $t('policy_sub4_table_tr2_td2') }}</td>
                    <td>{{ $t('policy_sub4_table_tr2_td3') }}</td>
                    <td>{{ $t('policy_sub4_table_tr2_td4') }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t('policy_sub4_table_tr3_td1') }}</td>
                    <td>{{ $t('policy_sub4_table_tr3_td2') }}</td>
                    <td>{{ $t('policy_sub4_table_tr3_td3') }}</td>
                    <td>{{ $t('policy_sub4_table_tr3_td4') }}</td>
                  </tr>
                </tbody>
              </table>

              <table class="mobile table table-bordered">
                <tbody>
                  <tr>
                    <td style="background-color: #f4f2eb; font-weight: bold">{{ $t('policy_sub4_table_tr1_td1') }}</td>
                    <td>{{ $t('policy_sub4_table_tr2_td1') }}</td>
                    <td>{{ $t('policy_sub4_table_tr3_td1') }}</td>
                  </tr>
                  <tr>
                    <td style="background-color: #f4f2eb; font-weight: bold">{{ $t('policy_sub4_table_tr1_td2') }}</td>
                    <td>{{ $t('policy_sub4_table_tr2_td2') }}</td>
                    <td>{{ $t('policy_sub4_table_tr3_td2') }}</td>
                  </tr>
                  <tr>
                    <td style="background-color: #f4f2eb; font-weight: bold">{{ $t('policy_sub4_table_tr1_td3') }}</td>
                    <td>{{ $t('policy_sub4_table_tr2_td3') }}</td>
                    <td>{{ $t('policy_sub4_table_tr3_td3') }}</td>
                  </tr>
                  <tr>
                    <td style="background-color: #f4f2eb; font-weight: bold">{{ $t('policy_sub4_table_tr1_td4') }}</td>
                    <td>{{ $t('policy_sub4_table_tr2_td4') }}</td>
                    <td>{{ $t('policy_sub4_table_tr3_td4') }}</td>
                  </tr>
                </tbody>
              </table>
            </section>

            <section ref="section-05" class="mt-5">
              <h5 class="mb-4">
                <strong>{{ $t('policy_sub5_title') }}</strong>
              </h5>
              <p class="numText1">{{ $t('policy_sub5_desc1') }}</p>
              <table class="table table-bordered">
                <tbody align="center">
                  <tr style="background-color: #f4f2eb; font-weight: bold">
                    <td>{{ $t('policy_sub5_table_tr1_td1') }}</td>
                    <td>{{ $t('policy_sub5_table_tr1_td2') }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t('policy_sub5_table_tr2_td1') }}</td>
                    <td>{{ $t('policy_sub5_table_tr2_td2') }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t('policy_sub5_table_tr3_td1') }}</td>
                    <td>{{ $t('policy_sub5_table_tr3_td2') }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t('policy_sub5_table_tr4_td1') }}</td>
                    <td>{{ $t('policy_sub5_table_tr4_td2') }}</td>
                  </tr>
                </tbody>
              </table>

              <p class="numText1">{{ $t('policy_sub5_desc2') }}</p>
              <p class="numText1">{{ $t('policy_sub5_desc3') }}</p>
            </section>

            <section ref="section-06" class="mt-5">
              <h5 class="mb-4">
                <strong>{{ $t('policy_sub6_title') }}</strong>
              </h5>
              <p class="numText1">{{ $t('policy_sub6_desc1') }}</p>
              <p class="numText1">{{ $t('policy_sub6_desc2') }}</p>

              <p class="numText1">{{ $t('policy_sub6_desc3') }}</p>
              <p class="numText2">{{ $t('policy_sub6_desc3_1') }}</p>
              <p class="numText3">{{ $t('policy_sub6_desc3_2') }}</p>
              <p class="numText2">{{ $t('policy_sub6_desc3_3') }}</p>
              <p class="numText3">{{ $t('policy_sub6_desc3_4') }}</p>
              <p class="numText2">{{ $t('policy_sub6_desc3_5') }}</p>
              <p class="numText3">{{ $t('policy_sub6_desc3_6') }}</p>
            </section>

            <section ref="section-07" class="mt-5">
              <h5 class="mb-4">
                <strong>{{ $t('policy_sub7_title') }}</strong>
              </h5>
              <p class="numText1">{{ $t('policy_sub7_desc1') }}</p>
              <p class="numText2">{{ $t('policy_sub7_desc1_1') }}</p>
              <p class="numText2">{{ $t('policy_sub7_desc1_2') }}</p>
              <p class="numText2">{{ $t('policy_sub7_desc1_3') }}</p>
              <p class="numText2">{{ $t('policy_sub7_desc1_4') }}</p>
              <p class="numText1">{{ $t('policy_sub7_desc2') }}</p>
              <p class="numText1">{{ $t('policy_sub7_desc3') }}</p>
              <p class="numText1">{{ $t('policy_sub7_desc4') }}</p>
              <p class="numText1">{{ $t('policy_sub7_desc5') }}</p>
              <p class="numText1">{{ $t('policy_sub7_desc6') }}</p>
              <p class="numText1">{{ $t('policy_sub7_desc7') }}</p>
            </section>

            <section ref="section-08" class="mt-5">
              <h5 class="mb-4">
                <strong>{{ $t('policy_sub8_1_title') }}</strong>
              </h5>
              <p class="numText1">{{ $t('policy_sub8_desc1') }}</p>
              <p class="numText1">{{ $t('policy_sub8_desc2') }}</p>
              <p class="numText2">{{ $t('policy_sub8_desc2_1') }}</p>
              <p class="numText3">{{ $t('policy_sub8_desc2_2') }}</p>
              <p class="numText2">{{ $t('policy_sub8_desc2_3') }}</p>
              <p class="numText3">{{ $t('policy_sub8_desc2_4') }}</p>
              <p class="numText2">{{ $t('policy_sub8_desc2_5') }}</p>
            </section>

            <section ref="section-09" class="mt-5">
              <h5 class="mb-4">
                <strong>{{ $t('policy_sub9_title') }}</strong>
              </h5>
              <p>{{ $t('policy_sub9_desc1') }}</p>
            </section>

            <section ref="section-10" class="mt-5">
              <h5 class="mb-4">
                <strong>{{ $t('policy_sub10_title') }}</strong>
              </h5>
              <p>{{ $t('policy_sub10_desc1') }}</p>
              <p class="numText2" v-html="$t('policy_sub10_table_tr1_td1')"></p>
              <p class="numText2" v-html="$t('policy_sub10_table_tr2_td1')"></p>
            </section>

            <section ref="section-11" class="mt-5">
              <h5 class="mb-4">
                <strong>{{ $t('policy_sub11_title') }}</strong>
              </h5>
              <p>{{ $t('policy_sub11_desc1') }}</p>
              <p class="numText1">{{ $t('policy_sub11_desc2') }}</p>
              <p class="innerText">{{ $t('policy_sub11_desc3') }}<br /></p>
              <p class="numText1">{{ $t('policy_sub11_desc4') }}</p>
              <p class="innerText">{{ $t('policy_sub11_desc5') }}<br /></p>
              <p class="numText1">{{ $t('policy_sub11_desc6') }}</p>
              <p class="innerText">{{ $t('policy_sub11_desc7') }}<br /></p>
              <p class="numText1">{{ $t('policy_sub11_desc8') }}</p>
              <p class="innerText">{{ $t('policy_sub11_desc9') }}<br /></p>
              <p class="numText1">{{ $t('policy_sub11_desc10') }}</p>
              <p class="innerText">{{ $t('policy_sub11_desc11') }}<br /></p>
              <p class="numText1">{{ $t('policy_sub11_desc12') }}</p>
              <p class="innerText">{{ $t('policy_sub11_desc13') }}<br /></p>
              <p class="numText1">{{ $t('policy_sub11_desc14') }}</p>
              <p class="innerText">{{ $t('policy_sub11_desc15') }}<br /></p>
              <p class="numText1">{{ $t('policy_sub11_desc16') }}</p>
              <p class="innerText">{{ $t('policy_sub11_desc17') }}<br /></p>
              <p class="numText1">{{ $t('policy_sub11_desc18') }}</p>
              <p class="innerText">{{ $t('policy_sub11_desc19') }}<br /></p>
              <p class="numText1">{{ $t('policy_sub11_desc20') }}</p>
              <p class="innerText">{{ $t('policy_sub11_desc21') }}<br /></p>
              <p class="numText1">
                {{ $t('policy_sub11_desc22') }}
              </p>
              <p class="numText1">
                {{ $t('policy_sub11_desc23') }}
              </p>
            </section>

            <section ref="section-12" class="mt-5">
              <h5 class="mb-4">
                <strong>{{ $t('policy_sub12_title') }}</strong>
              </h5>
              <p>{{ $t('policy_sub12_desc1') }}</p>
              <p class="numText1">{{ $t('policy_sub12_1_desc1') }}</p>
              <p class="innerText">
                {{ $t('policy_sub12_1_desc2') }}
              </p>
              <p class="numText1">{{ $t('policy_sub12_2_desc1') }}</p>
              <table class="table table-bordered">
                <tbody align="center">
                  <tr style="background-color: #f4f2eb; font-weight: bold">
                    <td width="20%">{{ $t('policy_sub12_2_table_tr1_td1') }}</td>
                    <td>{{ $t('policy_sub12_2_table_tr1_td2') }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t('policy_sub12_2_table_tr2_td1') }}</td>
                    <td>{{ $t('policy_sub12_2_table_tr2_td2') }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t('policy_sub12_2_table_tr3_td1') }}</td>
                    <td>{{ $t('policy_sub12_2_table_tr3_td2') }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t('policy_sub12_2_table_tr4_td1') }}</td>
                    <td>{{ $t('policy_sub12_2_table_tr4_td2') }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t('policy_sub12_2_table_tr5_td1') }}</td>
                    <td>{{ $t('policy_sub12_2_table_tr5_td2') }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t('policy_sub12_2_table_tr6_td1') }}</td>
                    <td>{{ $t('policy_sub12_2_table_tr6_td2') }}</td>
                  </tr>
                </tbody>
              </table>

              <p class="numText1">{{ $t('policy_sub12_3_desc1') }}</p>
              <p class="innerText">{{ $t('policy_sub12_3_desc2') }}</p>
              <table class="web table table-bordered">
                <tbody align="center">
                  <tr style="background-color: #f4f2eb; font-weight: bold">
                    <td>{{ $t('policy_sub12_3_table_tr1_td1') }}</td>
                    <td>{{ $t('policy_sub12_3_table_tr1_td2') }}</td>
                    <td>{{ $t('policy_sub12_3_table_tr1_td3') }}</td>
                    <td>{{ $t('policy_sub12_3_table_tr1_td4') }}</td>
                    <td>{{ $t('policy_sub12_3_table_tr1_td5') }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t('policy_sub12_3_table_tr2_td1') }}</td>
                    <td>{{ $t('policy_sub12_3_table_tr2_td2') }}</td>
                    <td>{{ $t('policy_sub12_3_table_tr2_td3') }}</td>
                    <td>{{ $t('policy_sub12_3_table_tr2_td4') }}</td>
                    <td>{{ $t('policy_sub12_3_table_tr2_td5') }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t('policy_sub12_3_table_tr3_td1') }}</td>
                    <td>{{ $t('policy_sub12_3_table_tr3_td2') }}</td>
                    <td>{{ $t('policy_sub12_3_table_tr3_td3') }}</td>
                    <td>{{ $t('policy_sub12_3_table_tr3_td4') }}</td>
                    <td>{{ $t('policy_sub12_3_table_tr3_td5') }}</td>
                  </tr>
                </tbody>
              </table>

              <table class="mobile table table-bordered">
                <tbody align="center">
                  <tr style="background-color: #f4f2eb; font-weight: bold">
                    <td>{{ $t('policy_sub12_3_table_tr1_td1') }}</td>
                    <td>{{ $t('policy_sub12_3_table_tr2_td1') }}</td>
                    <td>{{ $t('policy_sub12_3_table_tr3_td1') }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t('policy_sub12_3_table_tr1_td2') }}</td>
                    <td>{{ $t('policy_sub12_3_table_tr2_td2') }}</td>
                    <td>{{ $t('policy_sub12_3_table_tr3_td2') }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t('policy_sub12_3_table_tr1_td3') }}</td>
                    <td>{{ $t('policy_sub12_3_table_tr2_td3') }}</td>
                    <td>{{ $t('policy_sub12_3_table_tr3_td3') }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t('policy_sub12_3_table_tr1_td4') }}</td>
                    <td>{{ $t('policy_sub12_3_table_tr2_td4') }}</td>
                    <td>{{ $t('policy_sub12_3_table_tr3_td4') }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t('policy_sub12_3_table_tr1_td5') }}</td>
                    <td>{{ $t('policy_sub12_3_table_tr2_td5') }}</td>
                    <td>{{ $t('policy_sub12_3_table_tr3_td5') }}</td>
                  </tr>
                </tbody>
              </table>
              <p class="numText1">{{ $t('policy_sub12_4_desc1') }}</p>

              <table class="table table-bordered">
                <tbody align="center">
                  <tr style="background-color: #f4f2eb; font-weight: bold">
                    <td width="20%">{{ $t('policy_sub12_4_table_tr1_td1') }}</td>
                    <td width="30%">{{ $t('policy_sub12_4_table_tr1_td2') }}</td>
                    <td>{{ $t('policy_sub12_4_table_tr1_td3') }}</td>
                  </tr>
                  <tr>
                    <td rowspan="2">{{ $t('policy_sub12_4_table_tr2_td1') }}</td>
                    <td>{{ $t('policy_sub12_4_table_tr2_td2') }}</td>
                    <td v-html="$t('policy_sub12_4_table_tr2_td3')"></td>
                  </tr>
                  <tr>
                    <td>{{ $t('policy_sub12_4_table_tr3_td2') }}</td>
                    <td>{{ $t('policy_sub12_4_table_tr3_td3') }}</td>
                  </tr>
                </tbody>
              </table>
              <p style="margin-left: 5rem; text-indent: -4.8rem">{{ $t('policy_sub12_4_desc2') }}</p>

              <p class="numText1">{{ $t('policy_sub12_5_desc1') }}</p>
              <p class="numText2">{{ $t('policy_sub12_5_desc2') }}</p>
              <p class="numText2">{{ $t('policy_sub12_5_desc3') }}</p>
              <p class="numText1">{{ $t('policy_sub12_6_desc1') }}</p>
              <p class="innerText">{{ $t('policy_sub12_6_desc2') }}</p>
              <p class="numText1">{{ $t('policy_sub12_7_desc1') }}</p>
              <p class="innerText">{{ $t('policy_sub12_7_desc2') }}</p>
            </section>

            <section ref="section-13" class="mt-5">
              <h5 class="mb-4">
                <strong>{{ $t('policy_sub13_title') }}</strong>
              </h5>
              <p>{{ $t('policy_sub13_desc1') }}</p>
              <p class="numText1">{{ $t('policy_sub13_1_desc1') }}</p>
              <p class="numText2">{{ $t('policy_sub13_1_desc2') }}</p>
              <p class="numText2">{{ $t('policy_sub13_1_desc3') }}</p>
              <p class="numText2">{{ $t('policy_sub13_1_desc4') }}</p>
              <p class="numText2">{{ $t('policy_sub13_1_desc5') }}</p>
              <p class="numText1">{{ $t('policy_sub13_2_desc1') }}</p>
              <p class="numText2">{{ $t('policy_sub13_2_desc2') }}</p>
              <p class="numText2">{{ $t('policy_sub13_2_desc3') }}</p>
              <p class="numText2">{{ $t('policy_sub13_2_desc4') }}</p>
              <p class="numText2">{{ $t('policy_sub13_2_desc5') }}</p>
              <p class="numText1">{{ $t('policy_sub13_3_desc1') }}</p>
              <p class="numText2">{{ $t('policy_sub13_3_desc2') }}</p>
              <p class="numText2">{{ $t('policy_sub13_3_desc3') }}</p>
              <p class="numText1">{{ $t('policy_sub13_4_desc1') }}</p>
              <p class="numText2">{{ $t('policy_sub13_4_desc2') }}</p>
              <p class="numText2">{{ $t('policy_sub13_4_desc3') }}</p>
            </section>
            <section ref="section-14" class="mt-5">
              <h5 class="mb-4">
                <strong>{{ $t('policy_sub14_title') }}</strong>
              </h5>
              <p>{{ $t('policy_sub14_desc1') }}</p>
              <p class="innerText">{{ $t('policy_sub14_desc2') }}</p>
              <p class="innerText">
                {{ $t('policy_sub14_desc3')
                }}<button v-if="isLang === 0" class="button b-black" @click="prevPage('20150526')">
                  이전 개인정보처리방침 보기
                </button>
              </p>
              <p class="innerText">
                {{ $t('policy_sub14_desc4')
                }}<button v-if="isLang === 0" class="button b-black" @click="prevPage('20160920')">
                  이전 개인정보처리방침 보기
                </button>
              </p>
              <p class="innerText">
                {{ $t('policy_sub14_desc5')
                }}<button v-if="isLang === 0" class="button b-black" @click="prevPage('20181123')">
                  이전 개인정보처리방침 보기
                </button>
              </p>
              <p class="innerText">
                {{ $t('policy_sub14_desc6')
                }}<button v-if="isLang === 0" class="button b-black" @click="prevPage('20230817')">
                  이전 개인정보처리방침 보기
                </button>
              </p>
              <p class="innerText">{{ $t('policy_sub14_desc7') }}</p>
            </section>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLang: 1,
    };
  },
  watch: {
    '$i18n.locale'(newVal, oldVal) {
      this.refreshLocale(newVal);
    },
  },
  created() {
    this.refreshLocale(this.$i18n.locale);
  },
  methods: {
    goSection(idx) {
      this.$refs[`section-${idx}`].scrollIntoView();
    },
    prevPage(idx) {
      this.$parent.launchEditForm(idx);
    },
    refreshLocale(newVal) {
      if (newVal === 'ko') {
        this.isLang = 0;
      } else if (newVal === 'en') {
        this.isLang = 1;
      } else if (newVal === 'ja') {
        this.isLang = 2;
      } else {
        this.isLang = 0;
      }
    },
  },
};
</script>

<style scoped lang="scss">
a,
p,
h1,
h2,
h3,
h4,
h5,
td {
  word-break: keep-all;
}
.bookmark-links-kr a {
  float: left;
  display: flex;
  cursor: pointer;

  border: 1px solid #bfbfbf;
  background-color: #f4f2eb;
  padding: 0.5rem;
  margin: 0.1rem;
  width: 33%;
  height: 3rem;
  align-items: center;

  font-size: 0.8rem;
  box-sizing: border-box;
}
.bookmark-links-en a {
  float: left;
  display: flex;
  cursor: pointer;

  border: 1px solid #bfbfbf;
  background-color: #f4f2eb;
  padding: 0.5rem;
  margin: 0.1rem;
  width: 33%;
  height: 4rem;
  align-items: center;
  font-size: 0.8rem;
  box-sizing: border-box;
}
td {
  vertical-align: middle;
}
a {
  color: #333;
}
.button.b-black {
  margin: 0rem 1rem;
  background-color: #333333;
  color: #fff;
}
.button {
  position: relative;
  display: inline-block;

  box-sizing: border-box;
  font-size: 0.9rem;
  text-align: center;
}
.numText1 {
  margin-left: 1rem;
  text-indent: -1rem;
  font-weight: bold;
}
.numText2 {
  margin-left: 2.5rem;
  text-indent: -1.5rem;
}
.numText3 {
  margin-left: 2.5rem;
  text-indent: 0rem;
}
.innerText {
  margin: 1rem;
}
table_line_align {
  font-size: 0.88rem;
  margin-left: 1rem;
  text-indent: -1rem;
}

@media (min-width: 1200px) {
  .web {
    display: table;
  }
  .mobile {
    display: none;
  }
  .bookmark-links-kr a {
    width: 33%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .web {
    display: table;
  }
  .mobile {
    display: none;
  }
  .bookmark-links-kr a {
    width: 49%;
  }
  .bookmark-links-en a {
    width: 49%;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .web {
    display: none;
  }
  .mobile {
    display: table;
  }
  .bookmark-links-kr a {
    width: 49%;
  }
  .bookmark-links-en a {
    width: 49%;
  }
}
@media (max-width: 767px) {
  .web {
    display: none;
  }
  .mobile {
    display: table;
  }
  .bookmark-links-kr a {
    width: 100%;
  }
  .bookmark-links-en a {
    width: 100%;
  }
}
</style>

<i18n>
{
  "ko": {
    "Policy_title": "개인정보보호 처리방침",
    "policy_desc": "SK핀크스(주)(이하 '회사'라 함)는 고객의 개인정보를 매우 중요하게 생각하며, ‘개인정보보호법’ 등 관련법령을 준수하고 있습니다. 회사는 개인정보 처리방침을 통하여 고객께서 제공하신 개인정보가 어떠한 용도와 방식으로 처리되고 있고, 개인정보보호를 위하여 어떤 조치가 취해지고 있는지 알려드립니다. 회사는 개인정보처리방침을 개정하는 경우, 웹사이트 공지사항 (또는 개별공지)을 통하여 공지할 것입니다. 회사의 개인정보 처리방침은 다음과 같은 내용을 담고 있습니다.",
    "policy_sub1_title": "제 1조 개인정보의 처리 목적",
    "policy_sub2_title": "제 2조 개인정보의 수집항목 및 이용목적",
    "policy_sub3_title": "제 3조 개인정보의 처리기간",
    "policy_sub4_title": "제 4조 개인정보의 제3자 제공",
    "policy_sub5_title": "제 5조 개인정보 수탁업체 및 업무내용",
    "policy_sub6_title": "제 6조 개인정보의 파기절차 및 방법",
    "policy_sub7_title": "제 7조 정보주체의 권리 및 행사방법",
    "policy_sub8_title": "제 8조 쿠키에 의한 개인정보 수집",
    "policy_sub8_1_title" : "제 8조 개인정보를 자동으로 수집하는 장치의 설치/ 운영 및 거부에 관한 사항",
    "policy_sub9_title": "제 9조 만19세 미만 아동의 개인정보보호",
    "policy_sub10_title": "제 10조 개인정보 보호책임자 및 열람 청구 접수 및 처리부서",
    "policy_sub11_title": "제 11조 개인정보의 안전성 확보조치",
    "policy_sub12_title": "제 12조 영상정보처리기기 운영관리에 관한 사항",
    "policy_sub13_title": "제 13조 정보주체의 권익침해에 대한 구제방법",
    "policy_sub14_title": "제 14조 개인정보 처리방침의 변경에 관한 사항",
    "policy_sub1_desc": "회사는 다음 각 항목과 같은 목적으로 고객의 개인정보를 처리하고 있습니다. 처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며, 이용 목적이 변경되는 경우에는 개인정보보호법 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.",
    "policy_sub1_1_title": "1. 홈페이지, 멤버십 회원 가입 및 관리",
    "policy_sub1_1_desc": "회원 가입의사 확인, 회원 서비스 제공에 따른 본인 식별∙인증, 맴버십자격유지∙관리, 제한적 본인 확인제 시행에 따른 본인확인, 서비스 부정이용 방지, 각종 고지∙통지, 처리결과 통보 등의 목적으로 개인정보를 처리합니다.",
    "policy_sub1_2_title": "2. 재화 또는 서비스 제공",
    "policy_sub1_2_desc": "물품배송, 예약 및 서비스 제공, 계약서∙청구서 발송, 콘텐츠 제공, 맞춤서비스 제공, 본인인증, 연령인증, 요금결제∙정산, 채권추심 등을 목적으로 개인정보를 처리합니다.",
    "policy_sub1_3_title": "3. 고객문의 처리",
    "policy_sub1_3_desc": "민원인의 신원 확인, 민원사항 확인, 사실조사를 위한 연락통지, 처리결과 등의 목적으로 개인정보를 처리합니다.",
    "policy_sub1_4_title": "4. 마케팅 및 광고활용",
    "policy_sub1_4_desc": "이벤트 등 광고성 정보 전달, 이벤트 당첨시 물품 배송, 신규 서비스 개발 및 특화, 인구통계학적 특성에 따른 서비스 제공 및 광고 게재, 홈페이지 뉴스레터 발송, 접속빈도 파악 또는 회원의 서비스 이용에 대한 통계 목적으로 개인정보를 처리합니다.",
    "policy_sub2_desc": "회사는 다음과 같은 목적을 위해 개인정보를 수집하고 있습니다.",
    "policy_sub2_table_tr1_td1": "구분",
    "policy_sub2_table_tr1_td2": "수집항목",
    "policy_sub2_table_tr1_td3": "수집목적",
    "policy_sub2_table_tr1_td4": "수집방법",
    "policy_sub2_table_tr1_td5": "보유기간",
    "policy_sub2_table_tr2_td1": "멤버십",
    "policy_sub2_table_tr2_td2": "성명, 생년월일, 휴대폰번호, 이메일주소, 아이디, 비밀번호, 예약내역",
    "policy_sub2_table_tr2_td3": "- 멤버십서비스에 대한 본인확인 절차<br/>- 멤버십 혜택, 약관변경, 고지사항 전달<br/>- 문의/상담 및 불만등에 대한 민원처리",
    "policy_sub2_table_tr2_td4": "홈페이지",
    "policy_sub2_table_tr2_td5": "탈퇴 후 1년",
    "policy_sub2_table_tr3_td1": "온라인예약<br/>(호텔)",
    "policy_sub2_table_tr3_td2": "성명, 연락처",
    "policy_sub2_table_tr3_td3": "- 서비스이용자 식별 및 본인확인<br/>- 예약내역 및 안내고지<br/>- 문의/상담 및 불만등에 대한 민원처리<br/>- 부정 이용 방지 및 법적 분쟁등의 처리",
    "policy_sub2_table_tr3_td4": "홈페이지",
    "policy_sub2_table_tr3_td5": "1년<br/>(체크아웃기준)",
    "policy_sub2_table_tr4_td2": "신용카드정보(신용카드종류, 카드번호, 카드유효기한)",
    "policy_sub2_table_tr4_td3": "객실 및 부대시설 이용요금에 대한 보증 및 서비스 제공",
    "policy_sub2_table_tr4_td4": "홈페이지",
    "policy_sub2_table_tr4_td5": "30일<br/>(체크아웃기준)",
    "policy_sub2_table_tr5_td1": "온라인예약<br/>(골프)",
    "policy_sub2_table_tr5_td2": "성명, 연락처",
    "policy_sub2_table_tr5_td3": "- 서비스이용자 식별 및 본인확인<br/>- 예약내역 및 안내고지<br/>- 문의/상담 및 불만등에 대한 민원처리<br/>- 부정 이용 방지 및 법적 분쟁등의 처리",
    "policy_sub2_table_tr5_td4": "홈페이지",
    "policy_sub2_table_tr5_td5": "1년<br/>(이용종료일기준)",
    "policy_sub2_table_tr6_td1": "온라인예약<br/>(다이닝)",
    "policy_sub2_table_tr6_td2": "성명, 연락처",
    "policy_sub2_table_tr6_td3": "- 서비스이용자 식별 및 본인확인<br/>- 예약내역 및 안내고지<br/>- 문의/상담 및 불만등에 대한 민원처리<br/>- 부정 이용 방지 및 법적 분쟁등의 처리",
    "policy_sub2_table_tr6_td4": "홈페이지",
    "policy_sub2_table_tr6_td5": "1년<br/>(이용종료일기준)",
    "policy_sub2_table_tr7_td1": "입실등록카드<br/>(호텔)",
    "policy_sub2_table_tr7_td2": "성명, 휴대폰번호, 투숙기간, 객실번호, 차량번호",
    "policy_sub2_table_tr7_td3": "- 서비스이용자 식별 및 본인확인<br/>- 예약내역 및 안내고지<br/>- 문의/상담 및 불만등에 대한 민원처리<br/>- 부정 이용 방지 및 법적 분쟁등의 처리<br/>- 마케팅활용",
    "policy_sub2_table_tr7_td4": "서면, 웹",
    "policy_sub2_table_tr7_td5": "30일",
    "policy_sub2_table_tr8_td1": "PTC",
    "policy_sub2_table_tr8_td2": "성명, 핸드폰번호, 레슨기간",
    "policy_sub2_table_tr8_td3": "- 서비스이용자 식별 및 본인확인<br/>- 예약내역 및 안내고지<br/>- 문의/상담 및 불만등에 대한 민원처리<br/>- 부정 이용 방지 및 법적 분쟁등의 처리<br/>",
    "policy_sub2_table_tr8_td4": "서면, 웹",
    "policy_sub2_table_tr8_td5": "1년",
    "policy_sub2_table_tr9_td1": "P-Leisure",
    "policy_sub2_table_tr9_td2": "성명, 연락처, 이메일주소",
    "policy_sub2_table_tr9_td3": "- 서비스이용자 식별 및 본인확인<br/>- 예약내역 및 안내고지<br/>- 문의/상담 및 불만등에 대한 민원처리<br/>- 부정 이용 방지 및 법적 분쟁등의 처리<br/>",
    "policy_sub2_table_tr9_td4": "홈페이지",
    "policy_sub2_table_tr9_td5": "1년",
    "policy_sub2_table_tr10_td1": "웨딩문의",
    "policy_sub2_table_tr10_td2": "성명, 연락처, 이메일주소",
    "policy_sub2_table_tr10_td3": "- 서비스이용자 식별 및 본인확인<br/>- 예약내역 및 안내고지<br/>- 문의/상담 및 불만등에 대한 민원처리<br/>- 부정 이용 방지 및 법적 분쟁등의 처리<br/>",
    "policy_sub2_table_tr10_td4": "홈페이지",
    "policy_sub2_table_tr10_td5": "목적 달성 후 파기",
    "policy_sub2_table_tr11_td1": "행사문의",
    "policy_sub2_table_tr11_td2": "성명, 연락처, 이메일주소",
    "policy_sub2_table_tr11_td3": "- 서비스이용자 식별 및 본인확인<br/>- 예약내역 및 안내고지<br/>- 문의/상담 및 불만등에 대한 민원처리<br/>- 부정 이용 방지 및 법적 분쟁등의 처리<br/>",
    "policy_sub2_table_tr11_td4": "홈페이지",
    "policy_sub2_table_tr11_td5": "목적 달성 후 파기",
    "policy_sub2_table_tr12_td1": "마케팅제휴<br/>문의",
    "policy_sub2_table_tr12_td2": "성명, 연락처, 직책, 이메일주소",
    "policy_sub2_table_tr12_td3": "- 서비스이용자 식별 및 본인확인<br/>- 예약내역 및 안내고지<br/>- 문의/상담 및 불만등에 대한 민원처리<br/>- 부정 이용 방지 및 법적 분쟁등의 처리<br/>",
    "policy_sub2_table_tr12_td4": "홈페이지",
    "policy_sub2_table_tr12_td5": "목적 달성 후 파기",
    "policy_sub2_table_tr13_td1": "고객의 소리",
    "policy_sub2_table_tr13_td2": "성명, 연락처, 이메일주소",
    "policy_sub2_table_tr13_td3": "- 서비스이용자 식별 및 본인확인<br/>- 문의/상담 및 불만등에 대한 민원처리<br/>- 부정 이용 방지 및 법적 분쟁등의 처리<br/>",
    "policy_sub2_table_tr13_td4": "홈페이지",
    "policy_sub2_table_tr13_td5": "민원처리 후 즉시 파기",
    "policy_sub2_table_desc1": "※ 서비스 이용과정에서 아래의 개인정보 항목이 생성되어 수집될 수 있습니다.<br />- IP주소, 쿠키, 서비스이용기록, 방문기록, 상담시 통화내역",
    "policy_sub3_desc1": "1. 회사는 '홈페이지' 탈퇴 요청이나 멤버십 회원의 개인정보 삭제 요청시에는 수집된 개인정보가 열람 또는 이용될 수 없도록 (즉시) 처리합니다. 다만, 홈페이지 가입 이력관리를 위해 아이디는 보관됩니다.",
    "policy_sub3_desc2": "2. 하지만, 해지 시 상법등 법령에 의하여 더 보존할 필요성이 있는 경우에는 법령에서 규정한 보존기한 동안 거래내역과 최소한의 기본정보를 보유할 수 있으며 보유기간을 회원님에게 미리 고지하거나 개별적으로 회원님의 동의를 받은 경우에는 약속한 보존기간동안 개인정보를 보유합니다. 이 경우 회사는 보유하는 정보를 그 보유 목적으로만 이용하며, 보존기간은 아래와 같습니다.",
    "policy_sub3_table_tr1_td1": "구분",
    "policy_sub3_table_tr1_td2": "보존 이유",
    "policy_sub3_table_tr1_td3": "보존기간",
    "policy_sub3_table_tr2_td1": "계약 또는 청약철회 등에 관한 기록",
    "policy_sub3_table_tr2_td2": "전자상거래 등에서의 소비자보호에 관한 법률",
    "policy_sub3_table_tr2_td3": "5년",
    "policy_sub3_table_tr3_td1": "대금결제 및 재화 등의 공급에 관한 기록",
    "policy_sub3_table_tr4_td1": "소비자의 불만 또는 분쟁처리에 관한 기록",
    "policy_sub3_table_tr4_td3": "3년",
    "policy_sub3_table_tr5_td1": "상업 장부와 영업에 관한 중요 서류",
    "policy_sub3_table_tr5_td2": "상법 제33조 (상업장부등의 보존)",
    "policy_sub3_table_tr5_td3": "10년",
    "policy_sub3_table_tr6_td1": "채권, 재산권에 관한 기록",
    "policy_sub3_table_tr6_td2": "민법 제162조 (채권, 재산권 소멸시효)",
    "policy_sub3_table_tr6_td3": "10년",
    "policy_sub3_table_tr7_td1": "기타소득관련 경비 등의 지출증명서류",
    "policy_sub3_table_tr7_td2": "소득세법 제160조의 2(경비 등의 지출증빙 수취 및 보관)",
    "policy_sub3_table_tr7_td3": "5년",
    "policy_sub3_table_tr8_td1": "세법이 규정하는 장부 및 증빙서류",
    "policy_sub3_table_tr8_td2": "국세기본법",
    "policy_sub3_table_tr8_td3": "5년",
    "policy_sub3_table_tr9_td1": "전자금융 거래에 관한 기록",
    "policy_sub3_table_tr9_td2": "전자금융거래법",
    "policy_sub3_table_tr9_td3": "5년",
    "policy_sub4_desc1": "1. 회사는 정보주체의 개인정보를 '개인정보의 처리목적', '수집하는 개인정보 항목 및 수집방법' 에서 명시한 범위 내에서만 처리하며, 정보주체의 동의, 법률의 특별한 규정 등 개인정보 보호법 제17조 및 제18조에 해당하는 경우에만 개인정보를 제3자에게 제공하고 그 이외에는 정보주체의 개인정보를 제3자에게 제공하지 않습니다.",
    "policy_sub4_desc1_1": "가. 정보주체로부터 별도의 동의를 받은 경우",
    "policy_sub4_desc1_2": "나. 법률에 특별한 규정이 있거나 법령상 의무를 준수하기 위하여 불가피한 경우",
    "policy_sub4_desc1_3": "다. 정보주체 또는 그 법정대리인이 의사표시를 할 수 없는 상태에 있거나 주소불명 등으로 사전 동의를 받을 수 없는 경우로서 명백히 정보주체 또는 제3자의 급박한 생명, 신체, 재산의 이익을 위하여 필요하다고 인정되는 경우",
    "policy_sub4_desc1_4": "라. 개인정보를 목적 외의 용도로 이용하거나 이를 제3자에게 제공하지 아니하면 다른 법률에서 정하는 소관 업무를 수행할 수 없는 경우로서 보호위원회의 심의-의결을 거친 경우",
    "policy_sub4_desc1_5": "마. 조약, 그 밖의 국제협정의 이행을 위하여 외국정부 또는 국제기구에 제공하기 위하여 필요한 경우",
    "policy_sub4_desc1_6": "바. 범죄의 수사와 공소의 제기 및 유지를 위하여 필요한 경우",
    "policy_sub4_desc1_7": "사. 법원의 재판업무 수행을 위하여 필요한 경우",
    "policy_sub4_desc1_8": "아. 형(刑) 및 감호, 보호처분의 집행을 위하여 필요한 경우",
    "policy_sub4_desc2": "2. 회사는 재난, 감염병, 급박한 생명∙신체 위험을 초래하는 사건∙사고. 급박한 재산 손실 등의 긴급상황이 발생하는 경우 정보주체의 동의 없이 관계기관에 개인정보를 제공할 수 있습니다. 이 경우 회사는 근거법령에 의거하여 필요한 최소한의 개인정보만을 제공하며, 목적과 다르게 제공하지 않겠습니다.",
    "policy_sub4_desc3": "3. 회사는 다음과 같이 개인정보를 제3자에게 제공하고 있습니다.",
    "policy_sub4_table_tr1_td1": "제공받는 자",
    "policy_sub4_table_tr1_td2": "제공목적",
    "policy_sub4_table_tr1_td3": "제공항목",
    "policy_sub4_table_tr1_td4": "보유 및 이용기간",
    "policy_sub4_table_tr2_td1": "국세청",
    "policy_sub4_table_tr2_td2": "골프회원권 명의개서",
    "policy_sub4_table_tr2_td3": "성명, 주민등록번호, 주소, 회원번호, 입회일자, 취득가, 매매가",
    "policy_sub4_table_tr2_td4": "5년",
    "policy_sub4_table_tr3_td1": "골프장경영협회",
    "policy_sub4_table_tr3_td2": "회원증서 발행",
    "policy_sub4_table_tr3_td3": "성명, 회원번호, 입회일자",
    "policy_sub4_table_tr3_td4": "회원 탈퇴시까지",
    "policy_sub5_desc1": "1. 회사는 개인정보 처리와 관련하여 아래와 같이 업무를 위탁하고 있으며, 관계법령에 따라 위탁 계약시 개인정보가 안전하게 관리될 수 있도록 필요한 사항을 규정하고 있습니다.",
    "policy_sub5_table_tr1_td1": "수탁업체",
    "policy_sub5_table_tr1_td2": "수탁업무내용",
    "policy_sub5_table_tr2_td1": "SK네트웍스서비스㈜",
    "policy_sub5_table_tr2_td2": "홈페이지 운영 및 관리",
    "policy_sub5_table_tr3_td1": "비즈톡 주식회사",
    "policy_sub5_table_tr3_td2": "문자, 알림톡 발송 서비스",
    "policy_sub5_table_tr4_td1": "SCI평가정보㈜",
    "policy_sub5_table_tr4_td2": "휴대폰을 통한 본인인증",
    "policy_sub5_desc2": "2. 회사는 위탁계약 체결시 개인정보보호법 제26조에 따라 위탁업무 수행목적 외 개인정보 처리금지, 기술적∙관리적 보호조치, 재위탁 제한, 수탁자에 대한 관리∙감독, 손해배상 등 책임에 관한 사항을 계약서 등 문서에 명시하고, 수탁자가 개인정보를 안전하게 처리하는지를 감독하고 있습니다.",
    "policy_sub5_desc3": "3. 위탁업무의 내용이나 수탁자가 변경될 경우에는 지체없이 본 개인정보 처리방침을 통하여 공개하도록 하겠습니다. 회사는 회원님의 서비스 유지 및 관리, 고객상담, 기타 서비스 안내를 위하여 전문 용역 업체에 일부 업무를 위탁 운영할 수 있습니다. 위탁을 받은 업체는 위탁을 받은 목적을 벗어나서 개인정보를 이용할 수 없습니다. 회사는 위탁처리되는 개인정보가 안전하게 관리될 수 있도록 수탁업체가 규정한 사항을 성실하게 이행하는 지 여부에 대하여 위탁한 업무의 범위내에서 적절한 감독을 행할 것입니다.",
    "policy_sub6_desc1": "1. 회사는 개인정보의 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다.",
    "policy_sub6_desc2": "2. 정보주체로부터 동의받은 개인정보 보유기간이 경과하거나 처리목적이 달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속 보존하여야 하는 경우에는, 해당 개인정보를 별도의 데이터베이스(DB)로 옮기거나 보관장소를 달리하여 일정기간 보관된 후 파기됩니다. 별도 데이터베이스 (종이문서의 경우 별도의 서류함)로 옮겨진 개인정보는 법률에 의한 경우가 아니고서는 보유되는 목적 이외의 다른 목적으로 이용되지 않습니다.",
    "policy_sub6_desc3": "3. 개인정보 파기의 절차 및 방법은 다음과 같습니다.",
    "policy_sub6_desc3_1": "1) 파기절차",
    "policy_sub6_desc3_2": "회사는 파기 사유가 발생한 개인정보를 선정하고, 회사의 개인정보 보호책임자의 승인을 받아 개인정보를 파기합니다.",
    "policy_sub6_desc3_3": "2) 파기방법",
    "policy_sub6_desc3_4": "회사는 전자적 파일 형태로 기록∙저장된 개인정보는 기록을 재생할 수 없도록 파기하며, 종이문서에 기록∙저장된 개인정보는 분쇄기로 분쇄하거나 소각하여 파기합니다.",
    "policy_sub6_desc3_5": "3) 파기기한",
    "policy_sub6_desc3_6": "정보주체의 개인정보는 개인정보의 보유기간이 경과된 경우에는 보유기간의 종료일로부터 5일 이내에 파기하며 개인정보의 처리 목적 달성, 해당 서비스의 폐지, 사업의 종료 등 그 개인정보가 불필요하게 되었을 때에는 개인정보의 처리가 불필요한 것으로 인정되는 날로부터 5일 이내에 그 개인정보를 파기합니다.",
    "policy_sub7_desc1": "1. 정보주체는 회사에 대해 언제든지 다음과 같이 개인정보보호 관련 권리를 행사할 수 있습니다.",
    "policy_sub7_desc1_1": "가. 개인정보 열람요구",
    "policy_sub7_desc1_2": "나. 오류 등이 있을 경우 정정 요구",
    "policy_sub7_desc1_3": "다. 삭제요구",
    "policy_sub7_desc1_4": "라. 처리정지 요구",
    "policy_sub7_desc2": "2. 제1항에 따른 권리행사는 회사에 대해 개인정보 보호법 시행령 제41조 제1항에 따라 서면, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며, 회사는 이에 대해 지체없이 조치하겠습니다. (개인정보 처리에 관한 고시(제2020-7호) 별지 제8호, 제10호 서식 참조)",
    "policy_sub7_desc3": "3. 정보주체가 개인정보의 오류 등에 대한 정정 또는 삭제를 요구한 경우에는 회사는 정정 또는 삭제를 완료할 때까지 해당 개인정보를 이용하거나 제공하지 않습니다.",
    "policy_sub7_desc4": "4. 제1항에 따른 권리행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수도 있습니다. 이 경우 '개인정보 처리 방법에 관한 고시(제2020-7호)' 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.",
    "policy_sub7_desc5": "5. 개인정보 열람 및 처리정지 요구는 개인정보보호법에 제35조 제4항, 제37조 제2항에 의하여 정보주체의 권리가 제한될 수 있습니다.",
    "policy_sub7_desc6": "6. 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.",
    "policy_sub7_desc7": "7. 회사는 정보주체 권리에 따른 열람의 요구, 정정∙삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다.",
    "policy_sub8_desc1": "1. 회사는 이용자에게 개별적인 맞춤서비스를 제공하기 위해 이용 정보를 저장하고 수시로 불러오는 '쿠키(cookie)'를 사용합니다.",
    "policy_sub8_desc2": "2. 쿠키는 웹사이트를 운영하는데 이용되는 서버가 이용자의 컴퓨터 브라우저에게 보내는 소량의 정보이며 이용자들의 PC 컴퓨터내의 하드디스크에 저장되기도 합니다.",
    "policy_sub8_desc2_1": "가. 쿠키(cookie)의 운용",
    "policy_sub8_desc2_2": "이용자가 방문한 각 서비스와 웹 사이트들에 대한 방문 및 이용행태, 인기검색어, 보안접속 여부 등을 파악하여 이용자에게 최적화된 정보 제공을 위해 사용됩니다.",
    "policy_sub8_desc2_3": "나. 쿠키(cookie)의 설치/운영 및 거부",
    "policy_sub8_desc2_4": "이용자는 쿠키 설치에 대한 선택권을 가지고 있으며, 웹브라우져에서 옵션을 설정하여 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치게 하거나 아니면 쿠기의 저장을 거부할 수 있습니다.",
    "policy_sub8_desc2_5": "다. 쿠키(cookie) 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수 있습니다.",
    "policy_sub9_desc1": "회사는 만19세 미만 아동의 개인정보를 수집하지 않으며, 회원 가입이 불가합니다.",
    "policy_sub10_desc1": "회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다. 개인정보와 관련된 문의사항이 있으시면 아래의 개인정보 보호책임자/보호담당자에게 연락주시기 바랍니다. 회사는 정보주체의 문의에 대해 지체없이 답변 및 처리해드릴 것입니다.",
    "policy_sub10_table_tr1_td1": "<strong>개인정보보호 책임자</strong><br />- 성명 : 이경수<br />- 소속 : 운영지원팀<br />- 직책 : 운영지원팀 팀장<br />- 이메일 : skpinx_it@skpinx.co.kr<br />- 전화번호 : 064-793-6070<br /><br />",
    "policy_sub10_table_tr2_td1": "<strong>개인정보보호 담당자</strong><br />- 성명 : 오예슬<br />- 소속 : ESG TF<br />- 직책 : ESG TF 매니저<br />- 이메일 :yeseul12@sk.com<br/>- 전화번호 : 064-793-5016<br/><br/>",
    "policy_sub11_desc1": "회사는 개인정보보호법 제29조에 따라 다음과 같이 안정성 확보에 필요한 기술적/관리적 및 물리적 조치를 취하고 있습니다.",
    "policy_sub11_desc2": "1. 개인정보 처리 인력의 최소화",
    "policy_sub11_desc3": "개인정보보호를 위해 개인정보 처리자에 대한 권한 부여를 최소화하고 있습니다.",
    "policy_sub11_desc4": "2. 개인정보 처리 인력에 대한 정기적 교육 시행",
    "policy_sub11_desc5": "개인정보보호에 대한 인식 제고를 위해 정기적인 교육을 시행하고 있습니다.",
    "policy_sub11_desc6": "3. 내부 점검 정기적 시행",
    "policy_sub11_desc7": "개인정보의 처리 관련 안정성 확보를 위해 정기적으로 자체 점검을 실시하고 있습니다.",
    "policy_sub11_desc8": "4. 내부 관리계획의 수립 및 시행",
    "policy_sub11_desc9": "개인정보의 안전한 처리 및 관리를 위해 내부관리계획을 수립하여 관리하고 있습니다.",
    "policy_sub11_desc10": "5. 개인정보의 암호화",
    "policy_sub11_desc11": "이용자의 개인정보와 비밀번호는 암호화되어 저장/관리되고 있으며 전송 시에도 별도의 보안기능을 사용하여 안전하게 관리하고 있습니다.",
    "policy_sub11_desc12": "6. 해킹 등에 대비한 기술적 대책",
    "policy_sub11_desc13": "회사는 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여 보안 프로그램을 설치하고 주기적인 갱신/점검을 하여 외부로부터 접근이 통제된 구역에 시스템을 설치하고 기술적, 물리적으로 감시 및 차단하고 있습니다.",
    "policy_sub11_desc14": "7. 개인정보에 대한 접근 제한",
    "policy_sub11_desc15": "개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의 부여, 변경, 말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한 조치를 하고 있으며 침입차단 시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있습니다.",
    "policy_sub11_desc16": "8. 접속기록의 보관 및 위변조 방지",
    "policy_sub11_desc17": "개인정보 처리시스템에 접속한 기록을 최소 1년 이상 보관, 관리하고 있으며 접속 기록이 위변조 및 도난 분실 되지 않도록 보안기능을 사용하고 있습니다.",
    "policy_sub11_desc18": "9. 문서보안을 위한 잠금장치 사용",
    "policy_sub11_desc19": "개인정보가 포함된 서류, 보조저장매체 등을 잠금장치가 있는 안전한 장소에 보관하고 있습니다.",
    "policy_sub11_desc20": "10. 비인가자에 대한 출입 통제",
    "policy_sub11_desc21": "개인정보를 보관하고 있는 물리적 보관 장소를 별도로 두고 이에 대해 출입통제 절차를 수립, 운영하고 있습니다.",
    "policy_sub11_desc22": "11. 정보주체 본인의 부주의나 인터넷 상의 문제로 ID, 비밀번호 등 개인정보가 유출되어 발생한 문제에 대해 회사는 일체의 책임을 지지 않습니다.",
    "policy_sub11_desc23": "12. 회사는 개인정보의 유출∙훼손 등 개인정보 침해사고 발생시 지체없이 정보주체에게 알리고 그 피해를 최소화 하기 위해 필요한 조치를 다합니다.",
    "policy_sub12_desc1": "회사는 아래와 같이 영상정보처리기기를 설치운영하고 있습니다.",
    "policy_sub12_1_desc1": "1. 영상정보처리기기의 설치근거 및 설치 목적",
    "policy_sub12_1_desc2": "회사에 출입하는 고객/구성원(직원 및 협력사)의 안전과 회사의 자산을 보호하고 범죄 및 각종 사고의 증거자료, 차량 도난 및 파손 방지, 화재 등 재난으로부터 시설/이용객의 안전확보 등 사실 증빙 자료로 활용할 목적으로 합니다.",
    "policy_sub12_2_desc1": "2. CCTV 정보",
    "policy_sub12_2_table_tr1_td1": "설치대수",
    "policy_sub12_2_table_tr1_td2": "설치위치 및 촬영범위",
    "policy_sub12_2_table_tr2_td1": "61대",
    "policy_sub12_2_table_tr2_td2": "골프장 클럽하우스 건물 내외부, 골프장 코스, 주차장",
    "policy_sub12_2_table_tr3_td1": "8대",
    "policy_sub12_2_table_tr3_td2": "후생관 건물 내외부, 주차장",
    "policy_sub12_2_table_tr4_td1": "25대",
    "policy_sub12_2_table_tr4_td2": "포도호텔 건물 내외부, 주차장",
    "policy_sub12_2_table_tr5_td1": "47대",
    "policy_sub12_2_table_tr5_td2": "디아넥스 건물 내외부, 주차장",
    "policy_sub12_2_table_tr6_td1": "16대",
    "policy_sub12_2_table_tr6_td2": "커뮤니티센터 건물 내외부, 주차장",
    "policy_sub12_3_desc1": "3. 총괄책임자 및 운영책임자",
    "policy_sub12_3_desc2": "영상정보를 보호하고 개인정보와 관련한 불만을 처리하기 위하여 아래와 같이 개인영상정보 보호책임자를 두고 있습니다.",
    "policy_sub12_3_table_tr1_td1": "구분",
    "policy_sub12_3_table_tr1_td2": "이름",
    "policy_sub12_3_table_tr1_td3": "직위",
    "policy_sub12_3_table_tr1_td4": "소속",
    "policy_sub12_3_table_tr1_td5": "연락처",
    "policy_sub12_3_table_tr2_td1": "총괄책임자",
    "policy_sub12_3_table_tr2_td2": "강창협",
    "policy_sub12_3_table_tr2_td3": "팀장",
    "policy_sub12_3_table_tr2_td4": "Infra 지원팀",
    "policy_sub12_3_table_tr2_td5": "064-793-6060",
    "policy_sub12_3_table_tr3_td1": "운영책임자",
    "policy_sub12_3_table_tr3_td2": "신창진",
    "policy_sub12_3_table_tr3_td3": "파트장",
    "policy_sub12_3_table_tr3_td4": "Infra 2파트",
    "policy_sub12_3_table_tr3_td5": "064-793-6061",
    "policy_sub12_4_desc1": "4. 영상정보 촬영시간, 보관기간, 보관장소, 처리방법",
    "policy_sub12_4_table_tr1_td1": "촬영시간",
    "policy_sub12_4_table_tr1_td2": "보관기간",
    "policy_sub12_4_table_tr1_td3": "보관장소",
    "policy_sub12_4_table_tr2_td1": "24시간",
    "policy_sub12_4_table_tr2_td2": "촬영일로부터 15일",
    "policy_sub12_4_table_tr2_td3": "골프장 클럽하우스 사무실, 후생관 통신실,<br />포도호텔 사무실, 커뮤니티센터 감시실",
    "policy_sub12_4_table_tr3_td2": "촬영일로부터 30일",
    "policy_sub12_4_table_tr3_td3": "디아넥스 방재실",
    "policy_sub12_4_desc2": "처리방법 : 개인영상정보의 목적외 이용, 제3자 제공, 파기, 열람 등 요구에 관한 사항을 기록∙관리하고, 보관기간 만료시 복원이 불가능한 방법으로 영구 삭제 (출력물의 경우 파쇄 또는 소각)합니다.",
    "policy_sub12_5_desc1": "5. 영상정보의 확인 방법 및 장소에 관한 사항",
    "policy_sub12_5_desc2": "- 확인방법 : 개인영상정보 보호책임자에게 미리 연락하고 당사를 방문하시면 확인 가능합니다.",
    "policy_sub12_5_desc3": "- 확인장소 : Infra 지원팀",
    "policy_sub12_6_desc1": "6. 정보주체의 영상정보 열람 등 요구에 대한 조치",
    "policy_sub12_6_desc2": "개인영상정보에 관하여 열람 또는 존재 확인, 삭제를 원하는 경우 언제든지 당사에 요구하실 수 있습니다. 단, 귀하가 촬영된 개인영상정보 및 명백히 정보주체의 급박한 생명, 신체, 재산의 이익을 위하여 필요한 개인영상정보에 한정됩니다. 당사는 개인영상정보에 관하여 열람 또는 존재 확인, 삭제를 요구한 경우 해당 요구를 검토하여 지체없이 필요한 조치를 취할 것이며 불가피하에 해당 요구를 거부하는 경우에는 그 사유를 통지하도록 하겠습니다.",
    "policy_sub12_7_desc1": "7. 영상정보의 안정성 확보조치",
    "policy_sub12_7_desc2": "당사는 폐쇄 네트워크 또는 방화벽 등의 보안시스템을 통하여 고객의 영상정보를 보호하고 있습니다. 또한, 개인영상정보 보호를 위하여 해당 영상정보를 취급하는 인원을 최소한으로 제한하고 있으며 지속적인 교육을 시행하고 있습니다. 개인영상정보의 임의적인 취급을 제한하기 위하여 개인영상정보의 열람 시 열람 목적, 열람자, 열람 일시 등을 기록, 관리하고 있으며 영상정보의 안전한 물리적 보관을 위하여 잠금장치를 설치하고 있습니다. 보안구역으로 정하고 사전 승인된 인력 이외에는 출입을 통제하고 있습니다.",
    "policy_sub13_desc1": "정보주체는 개인정보침해로 인한 구제를 받기 위하여 개인정보분쟁조정위원회, 한국인터넷진흥원 개인정보침해신고센터 등에 분쟁해결이나 상담 등을 신청할 수 있습니다. 이 밖에 기타 개인정보침해의 신고, 상담에 대하여는 아래의 기관에 문의하시기 바랍니다.",
    "policy_sub13_1_desc1": "1. 개인정보 침해신고센터 (한국인터넷진흥원 운영)",
    "policy_sub13_1_desc2": "- 소관업무 : 개인정보 침해사실 신고, 상담 신청",
    "policy_sub13_1_desc3": "- 홈페이지 : privacy.kisa.or.kr",
    "policy_sub13_1_desc4": "- 전화 : (국번없이) 118",
    "policy_sub13_1_desc5": "- 주소 : (58324) 전남 나주시 진흥길 9(빛가림동 301-2) 3층 개인정보침해신고센터",
    "policy_sub13_2_desc1": "2. 개인정보 분쟁조정위원회 (한국인터넷진흥원 운영)",
    "policy_sub13_2_desc2": "- 소관업무 : 개인정보 분쟁조정 신청, 집단분쟁조정 (민사적 해결)",
    "policy_sub13_2_desc3": "- 홈페이지 : www.kopico.go.kr",
    "policy_sub13_2_desc4": "- 전화 : (국번없이) 1833-6972",
    "policy_sub13_2_desc5": "- 주소 : (03171) 서울특별시 종로구 세종대로 209 정부서울청사 12층",
    "policy_sub13_3_desc1": "3. 대검찰청 사이버수사과",
    "policy_sub13_3_desc2": "- 전화 : (국번없이) 1301",
    "policy_sub13_3_desc3": "- 홈페이지 : www.spo.go.kr",
    "policy_sub13_4_desc1": "4. 경찰청 사이버수사국",
    "policy_sub13_4_desc2": "- 전화 : (국번없이) 182",
    "policy_sub13_4_desc3": "- 홈페이지 : ecrm.cyber.go.kr",
    "policy_sub14_desc1": "개인정보 처리방침은 2013년 9월1일에 제정되었으며, 법령 정책 또는 보안기술의 변경에 따라 내용의 추가 삭제 및 수정이 있을 시에는 변경되는 개인정보처리방침은 '홈페이지'를 통해 변경 이유 및 내용 등을 공지하도록 하겠습니다.",
    "policy_sub14_desc2": "- 최초 공고일자 : 2013년 09월 01일",
    "policy_sub14_desc3": "- 변경일자 : 2015년 05월 26일",
    "policy_sub14_desc4": "- 변경일자 : 2016년 09월 20일",
    "policy_sub14_desc5": "- 변경일자 : 2018년 11월 23일",
    "policy_sub14_desc6": "- 변경일자 : 2023년 08월 16일",
    "policy_sub14_desc7": "- 시행일자 : 2023년 10월 06일"
  },
  "en": {
    "Policy_title": "Personal Information Protection Policy",
    "policy_desc": "SK Pinx Co., Ltd. (hereinafter referred to as the 'Company') considers the protection of customer's personal information to be of utmost importance and complies with relevant laws and regulations, including the 'Personal Information Protection Act.' Through this personal information protection policy, the Company informs customers of the purposes and methods of processing the personal information provided, as well as the measures taken to protect personal information. In the event of any revisions to the personal information protection policy, the Company will announce such changes through the website notice (or individual notice). The Company's personal information protection policy includes the following contents:",
    "policy_sub1_title": "Article 1: Purpose of Processing Personal Information",
    "policy_sub2_title": "Article 2: Collection Items and Purposes of Use of Personal Information",
    "policy_sub3_title": "Article 3: Personal Information Processing Period",
    "policy_sub4_title": "Article 4: Provision of Personal Information to Third Parties",
    "policy_sub5_title": "Article 5: Personal Information Handling Contractors and Business Contents",
    "policy_sub6_title": "Article 6: Procedure and Method of Personal Information the Destruction",
    "policy_sub7_title": "Article 7: Rights of the Data Subject and Methods of Exercise",
    "policy_sub8_title": "Article 8: Collection of Personal Information through Cookies",
    "policy_sub8_1_title": "Article 8: Installation/Operation and Refusal of Devices Automatically Collecting Personal Information",
    "policy_sub9_title": "Article 9: Protection of Personal Information of Children Under 19 Years of Age",
    "policy_sub10_title": "Article 10: Personal Information Protection Manager and Department for Receiving and Processing Access Requests",
    "policy_sub11_title": "Article 11: Measures for Ensuring the Security of Personal Information",
    "policy_sub12_title": "Article 12: Matters Regarding the Operation and Management of Video Information Processing Devices",
    "policy_sub13_title": "Article 13: Remedies for Infringement of the Rights and Interests of Data Subjects",
    "policy_sub14_title": "Article 14: Matters Regarding Changes to the Personal Information Processing Policy",
    "policy_sub1_desc": "The company processes customer's personal information for the following purposes. The processed personal information will not be used for purposes other than those specified, and in the event of a change in the purpose of use, the company will take necessary measures, such as obtaining separate consent in accordance with Article 18 of the Personal Information Protection Act.",
    "policy_sub1_1_title": "1. Website and Membership Registration and Management",
    "policy_sub1_1_desc": "The company processes personal information for the purpose of confirming the intention of membership registration, identifying and authenticating members for the provision of membership services, maintaining and managing membership qualifications, confirming identity in accordance with the restricted identity verification system, preventing misuse of services, and providing various notices and notifications, as well as notifying processing results.",
    "policy_sub1_2_title": "2. Provision of Goods or Services",
    "policy_sub1_2_desc": "Personal information is processed for the purpose of delivering goods, making reservations and providing services, sending contracts and invoices, offering content, providing personalized services, authenticating individuals, verifying age, processing fees and settlements, and debt collection.",
    "policy_sub1_3_title": "3. Customer Inquiry Handling",
    "policy_sub1_3_desc": "Personal information is processed for the purpose of verifying the identity of the complainant, confirming the details of the complaint, notifying the complainant for fact-finding investigations, and communicating processing results.",
    "policy_sub1_4_title": "4. Marketing and Advertising Utilization",
    "policy_sub1_4_desc": "Personal information is processed for the purpose of delivering advertising information, such as event notifications, dispatching goods for event winners, developing and customizing new services, providing services and displaying advertisements based on demographic characteristics, sending newsletters through the website, understanding access frequency, and compiling statistics on members' service usage.",
    "policy_sub2_desc": "The company collects and processes personal information for the following purposes:",
    "policy_sub2_table_tr1_td1": "Category",
    "policy_sub2_table_tr1_td2": "Collected items",
    "policy_sub2_table_tr1_td3": "Purpose of collection",
    "policy_sub2_table_tr1_td4": "Collection method",
    "policy_sub2_table_tr1_td5": "Retention period",
    "policy_sub2_table_tr2_td1": "Membership",
    "policy_sub2_table_tr2_td2": "Name, DOB, Mobile Number, Email Address, ID, Password, Reservation History",
    "policy_sub2_table_tr2_td3": "- Verification procedure for membership services<br/>- Delivery of membership benefits, changes to terms and conditions, and notices<br/>- Handling inquiries, consultations, and complaints",
    "policy_sub2_table_tr2_td4": "Website",
    "policy_sub2_table_tr2_td5": "1 year after withdrawal",
    "policy_sub2_table_tr3_td1": "Online Reservation<br/>(Hotel)",
    "policy_sub2_table_tr3_td2": "Name, contact information",
    "policy_sub2_table_tr3_td3": "- Identification and authentication of service users<br/>- Notifications of reservation details and guidance<br/>- Handling inquiries, consultations, and complaints<br/>- Prevention of misuse and legal dispute resolution",
    "policy_sub2_table_tr3_td4": "Website",
    "policy_sub2_table_tr3_td5": "1 year<br/>(based on checkout date)",
    "policy_sub2_table_tr4_td2": "Credit card information (credit card type, card number, card expiration date)",
    "policy_sub2_table_tr4_td3": "Guarantee and service provision for room and facility usage fees",
    "policy_sub2_table_tr4_td4": "Website",
    "policy_sub2_table_tr4_td5": "30 days<br/>(based on checkout date)",
    "policy_sub2_table_tr5_td1": "Online Reservation<br/>(Golf)",
    "policy_sub2_table_tr5_td2": "Name, contact information",
    "policy_sub2_table_tr5_td3": "- Identification and authentication of service users<br/>- Notifications of reservation details and guidance<br/>- Handling inquiries, consultations, and complaints<br/>- Prevention of misuse and legal dispute resolution",
    "policy_sub2_table_tr5_td4": "Website",
    "policy_sub2_table_tr5_td5": "1 year<br/>(based on end date of usage)",
    "policy_sub2_table_tr6_td1": "Online Reservation<br/>(Dining)",
    "policy_sub2_table_tr6_td2": "Name, contact information",
    "policy_sub2_table_tr6_td3": "- Identification and authentication of service users<br/>- Notifications of reservation details and guidance<br/>- Handling inquiries, consultations, and complaints<br/>- Prevention of misuse and legal dispute resolution",
    "policy_sub2_table_tr6_td4": "Website",
    "policy_sub2_table_tr6_td5": "1 year<br/>(based on end date of usage)",
    "policy_sub2_table_tr7_td1": "Room registration card<br/>(Hotel)",
    "policy_sub2_table_tr7_td2": "Name, Mobile Number, length of stay, room number, vehicle number",
    "policy_sub2_table_tr7_td3": "- Identification and authentication of service users<br/>- Notifications of reservation details and guidance<br/>- Handling inquiries, consultations, and complaints<br/>- Prevention of misuse and legal dispute resolution<br/>- Using for marketing",
    "policy_sub2_table_tr7_td4": "Written, Web",
    "policy_sub2_table_tr7_td5": "30 days",
    "policy_sub2_table_tr8_td1": "PTC",
    "policy_sub2_table_tr8_td2": "Name, Mobile Number, lesson duration",
    "policy_sub2_table_tr8_td3": "- Identification and authentication of service users<br/>- Notifications of reservation details and guidance<br/>- Handling inquiries, consultations, and complaints<br/>- Prevention of misuse and legal dispute resolution<br/>",
    "policy_sub2_table_tr8_td4": "Written, Web",
    "policy_sub2_table_tr8_td5": "1 year",
    "policy_sub2_table_tr9_td1": "P-Leisure",
    "policy_sub2_table_tr9_td2": "Name, contact information, e-mail address",
    "policy_sub2_table_tr9_td3": "- Identification and authentication of service users<br/>- Notifications of reservation details and guidance<br/>- Handling inquiries, consultations, and complaints<br/>- Prevention of misuse and legal dispute resolution<br/>",
    "policy_sub2_table_tr9_td4": "Website",
    "policy_sub2_table_tr9_td5": "1 year",
    "policy_sub2_table_tr10_td1": "Wedding Inquiry",
    "policy_sub2_table_tr10_td2": "Name, contact information, e-mail address",
    "policy_sub2_table_tr10_td3": "- Identification and authentication of service users<br/>- Notifications of reservation details and guidance<br/>- Handling inquiries, consultations, and complaints<br/>- Prevention of misuse and legal dispute resolution<br/>",
    "policy_sub2_table_tr10_td4": "Website",
    "policy_sub2_table_tr10_td5": "Destroyed after achieving the purpose",
    "policy_sub2_table_tr11_td1": "Event Inquiry",
    "policy_sub2_table_tr11_td2": "Name, contact information, e-mail address",
    "policy_sub2_table_tr11_td3": "- Identification and authentication of service users<br/>- Notifications of reservation details and guidance<br/>- Handling inquiries, consultations, and complaints<br/>- Prevention of misuse and legal dispute resolution<br/>",
    "policy_sub2_table_tr11_td4": "Website",
    "policy_sub2_table_tr11_td5": "Destroyed after achieving the purpose",
    "policy_sub2_table_tr12_td1": "Marketing Partnership Inquiry",
    "policy_sub2_table_tr12_td2": "Name, contact information, e-mail address",
    "policy_sub2_table_tr12_td3": "- Identification and authentication of service users<br/>- Notifications of reservation details and guidance<br/>- Handling inquiries, consultations, and complaints<br/>- Prevention of misuse and legal dispute resolution<br/>",
    "policy_sub2_table_tr12_td4": "Website",
    "policy_sub2_table_tr12_td5": "Destroyed after achieving the purpose",
    "policy_sub2_table_tr13_td1": "Customer Feedback ",
    "policy_sub2_table_tr13_td2": "Name, contact information, e-mail address",
    "policy_sub2_table_tr13_td3": "- Identification and authentication of service users<br/>- Handling inquiries, consultations, and complaints<br/>- Prevention of misuse and legal dispute resolution<br/>",
    "policy_sub2_table_tr13_td4": "Website",
    "policy_sub2_table_tr13_td5": "Immediately destroyed after complaint processing",
    "policy_sub2_table_desc1": "※ The following personal information items may be created and collected during the service use process.<br />- IP address, cookies, service use records, visit records, call history during consultation",
    "policy_sub3_desc1": "1. When a user requests withdrawal from the 'website' or requests the deletion of personal information as a membership user, the collected personal information is processed (immediately) to ensure that it cannot be accessed or used. However, the user's ID is retained for the management of website registration history.",
    "policy_sub3_desc2": "2. However, in the event of termination, if there is a legal obligation under the Commercial Act or other relevant laws to retain the information for a longer period, the company may retain transaction records and minimal basic information for the period specified by the law. If the retention period is notified to the user in advance and agreed upon with the user individually, the personal information will be retained for the agreed-upon period. During this period, the company will only use the retained information for the intended purpose of retention. The retention periods are as follows.",
    "policy_sub3_table_tr1_td1": "Category",
    "policy_sub3_table_tr1_td2": "Retention Reasons",
    "policy_sub3_table_tr1_td3": "Retention period",
    "policy_sub3_table_tr2_td1": "Records related to contracts or withdrawal of subscription",
    "policy_sub3_table_tr2_td2": "Act On The Consumer Protection In Electronic Commerce",
    "policy_sub3_table_tr2_td3": "5 years",
    "policy_sub3_table_tr3_td1": "Records of payment and supply of goods",
    "policy_sub3_table_tr4_td1": "Records of consumer complaints or dispute resolution",
    "policy_sub3_table_tr4_td3": "3 years",
    "policy_sub3_table_tr5_td1": "Important documents related to commercial books and business",
    "policy_sub3_table_tr5_td2": "Commercial Act Article 33 (Preservation of Trade Books, etc.)",
    "policy_sub3_table_tr5_td3": "10 years",
    "policy_sub3_table_tr6_td1": "Records related to claims and property rights",
    "policy_sub3_table_tr6_td2": "Civil Act  Article 162 (Extinctive Prescription of Claims or Property Right)",
    "policy_sub3_table_tr6_td3": "10 years",
    "policy_sub3_table_tr7_td1": "Other income-related expenditure proof documents",
     "policy_sub3_table_tr7_td2": "Income Tax Act  Article 160-2 (Receipt and Keeping of Documentary Evidence of Disbursement of Expenses, etc.)",
    "policy_sub3_table_tr7_td3": "5 years",
    "policy_sub3_table_tr8_td1": "Books and supporting documents specified by tax laws",
    "policy_sub3_table_tr8_td2": "Framework Act On National Taxes",
    "policy_sub3_table_tr8_td3": "5 years",
    "policy_sub3_table_tr9_td1": "Records related to electronic financial transactions",
    "policy_sub3_table_tr9_td2": "Electronic Financial Transactions Act",
    "policy_sub3_table_tr9_td3": "5 years",
    "policy_sub4_desc1": "1. The company processes the personal information of the data subject only within the scope specified in 'Purpose of Processing Personal Information,' 'Items of Personal Information Collected and Collection Methods' and provides personal information to third parties only when the data subject has given consent or when there are specific regulations under Article 17 and 18 of the Personal Information Protection Act, such as special provisions of the law.",
    "policy_sub4_desc1_1": "A. When separate consent is obtained from the data subject",
    "policy_sub4_desc1_2": "B. When there are special provisions in the law or when it is unavoidable to comply with legal obligations",
    "policy_sub4_desc1_3": "C.  In cases where the data subject or their legal representative is unable to express their intention, or it is impossible to obtain prior consent due to reasons such as unknown address, and it is clearly recognized as necessary for the urgent interests of the data subject or a third party in terms of life, body or property.",
    "policy_sub4_desc1_4": "D.  When using personal information for purposes other than the original intent or providing it to a third party is necessary to perform tasks assigned by other laws, and this has been approved through deliberation and resolution by the Protection Committee",
    "policy_sub4_desc1_5": "E. When it is necessary to provide the information to a foreign government or international organization for the purpose of fulfilling a treaty or other international agreement",
    "policy_sub4_desc1_6": "F. When it is necessary for the investigation, filing, and maintenance of criminal prosecution",
    "policy_sub4_desc1_7": "G. When it is necessary for the performance of judicial duties by a court",
    "policy_sub4_desc1_8": "H. When it is necessary for the execution of punishment, custody, and protective measures",
    "policy_sub4_desc2": "2. In the event of emergencies such as disasters, infectious diseases, urgent threats to life or body, incidents, accidents, or urgent property losses, the company may provide personal information to relevant agencies without the consent of the data subject. In such cases, the company will provide only the minimum necessary personal information in accordance with applicable laws, and will not provide information for purposes other than the specified ones.",
    "policy_sub4_desc3": "3. The company is providing personal information to third parties as follows:",
    "policy_sub4_table_tr1_td1": "Recipient",
    "policy_sub4_table_tr1_td2": "Purpose of provision",
    "policy_sub4_table_tr1_td3": "Provided items ",
    "policy_sub4_table_tr1_td4": "Retention and use period",
    "policy_sub4_table_tr2_td1": "National Tax Service",
    "policy_sub4_table_tr2_td2": "Golf membership title transfer",
    "policy_sub4_table_tr2_td3": "Name, Korean ID number, address, membership number, joining date, acquisition price, sale price",
    "policy_sub4_table_tr2_td4": "5 years",
    "policy_sub4_table_tr3_td1": "Korea Golf Course Business Association",
    "policy_sub4_table_tr3_td2": "Issuance of membership certificate",
    "policy_sub4_table_tr3_td3": "Name, membership number, joining date",
    "policy_sub4_table_tr3_td4": "Until withdrawal from membership",
    "policy_sub5_desc1": "1. The company outsources certain tasks related to the processing of personal information, and in accordance with relevant laws and regulations, it establishes provisions in outsourcing contracts to ensure the secure management of personal information.",
    "policy_sub5_table_tr1_td1": "Entrusted agency",
    "policy_sub5_table_tr1_td2": "Entrusted work details ",
    "policy_sub5_table_tr2_td1": "SK Networks Service Co., Ltd.",
    "policy_sub5_table_tr2_td2": "Website operation and management",
    "policy_sub5_table_tr3_td1": "Biztalk Co., Ltd.",
    "policy_sub5_table_tr3_td2": "Text and notification message sending service",
    "policy_sub5_table_tr4_td1": "SCI Information Service Inc",
    "policy_sub5_table_tr4_td2": "Identity verification via mobile phone",
    "policy_sub5_desc2": "2. When entering into outsourcing agreements, the company, in compliance with Article 26 of the Personal Information Protection Act, specifies in contracts or documents the prohibition of processing personal information beyond the intended purposes of the outsourced tasks, the implementation of technical and managerial protective measures, restrictions on re-outsourcing, management and supervision of the entrusted party, and responsibilities such as indemnification for damages. The company actively monitors whether the entrusted party securely processes personal information.",
    "policy_sub5_desc3": "3. In the event of changes to the content of the entrusted tasks or the appointed entrusted agency, we will promptly disclose such modifications through this Personal Information Protection Policy. The company may engage specialized outsourcing agencies to operate certain tasks for the maintenance and management of your services, customer consultation, and other service notifications. The entrusted agencies are prohibited from using the personal information beyond the designated purposes for which it was entrusted. The company will diligently oversee, within the scope of the entrusted tasks, whether the entrusted agencies faithfully adhere to the stipulated requirements to ensure the secure management of the entrusted personal information.",
    "policy_sub6_desc1": "1. The company promptly destroys the personal information when it becomes unnecessary due to the expiration of the retention period or the achievement of the processing purposes.",
    "policy_sub6_desc2": "2. In cases where the personal information must be retained according to other laws despite the expiration of the agreed-upon retention period or the achievement of the processing purposes as consented by the data subject, the information will be transferred to a separate database or stored in a different location for a specified period before being destroyed. Personal information transferred to a separate database (in the case of paper documents, a separate filing cabinet) will not be used for purposes other than the original intended purpose, unless required by law.",
    "policy_sub6_desc3": "3. The procedures, deadlines, and methods for the destruction of personal information are as follows:",
    "policy_sub6_desc3_1": "1) Destruction Procedures",
    "policy_sub6_desc3_2": "The company selects personal information for destruction when the reasons for destruction arise and obtains approval from the company's Data Protection Officer before proceeding with the destruction.",
    "policy_sub6_desc3_3": "2) Destruction Methods",
    "policy_sub6_desc3_4": "For electronically recorded personal information, the company ensures irreversibility by destroying the records. Personal information recorded or stored in paper documents is shredded or incinerated for destruction.",
    "policy_sub6_desc3_5": "3) Destruction Deadline",
    "policy_sub6_desc3_6": "If the retention period of personal information for data subjects has expired, the company will destroy the information within five days from the end of the retention period. Additionally, when personal information becomes unnecessary due to the achievement of the processing purpose, termination of the relevant service, or cessation of the business, the personal information will be destroyed within five days from the date it is deemed unnecessary for processing.",
    "policy_sub7_desc1": "1. The data subject may exercise the following privacy-related rights against the company at any time:",
    "policy_sub7_desc1_1": "A. Request for access to personal information",
    "policy_sub7_desc1_2": "B. Request for correction if there are errors",
    "policy_sub7_desc1_3": "C. Request for deletion",
    "policy_sub7_desc1_4": "D. Request for suspension of processing",
    "policy_sub7_desc2": "2. The exercise of rights under paragraph 1 can be done against the company in accordance with Article 41(1) of the Enforcement Decree of the Personal Information Protection Act through written requests, email, facsimile (FAX), etc., and the company will promptly take necessary actions in response to such requests. (Refer to the Annex 8 and 10 forms of the Guidelines on the Processing of Personal Information (No. 2020-7))",
    "policy_sub7_desc3": "3. If a data subject requests correction or deletion of personal information due to errors, the company will refrain from using or providing such information until the correction or deletion is completed.",
    "policy_sub7_desc4": "4. The exercise of rights under paragraph 1 can also be conducted through a legal representative or an authorized agent of the data subject. In such cases, the representative must submit a power of attorney according to the format provided in Annex 11 of the 'Guidelines on the Processing of Personal Information (No. 2020-7).'",
    "policy_sub7_desc5": "5. The right of the data subject to request access to personal information or to demand the suspension of processing may be restricted under Article 35(4) and Article 37(2) of the Personal Information Protection Act.",
    "policy_sub7_desc6": "6. Requests for correction and deletion of personal information cannot be honored if the collection of that information is explicitly specified as required by other laws.",
    "policy_sub7_desc7": "7. When receiving requests for access, correction/deletion, or processing suspension according to the rights of the data subject, the company verifies whether the requester is the data subject or his/her legitimate representative.",
    "policy_sub8_desc1": "1. The company utilizes ‘cookies’ to store and periodically retrieve user information to provide personalized services.",
    "policy_sub8_desc2": "2. Cookies are small pieces of information sent by the server operating the website to the user's computer browser and may be stored on the hard drive of users' PC computers.",
    "policy_sub8_desc2_1": "A. Operation of Cookies",
    "policy_sub8_desc2_2": "Cookies are used to understand user behavior, such as visits and usage patterns for each service and website visited, popular search terms, and secure connection status, in order to provide optimized information to users.",
    "policy_sub8_desc2_3": "B. Installation/Operation and Refusal of Cookies",
    "policy_sub8_desc2_4": "Users have the option to choose regarding the installation of cookies. Users can configure their web browser options to allow all cookies, receive confirmation for each cookie storage, or refuse cookie storage.",
    "policy_sub8_desc2_5": "C. Refusing to store cookies may cause difficulties in using personalized services.",
    "policy_sub9_desc1": "The company does not collect the personal information of children under 19 years of age, and their membership registration is not allowed.",
    "policy_sub10_desc1": "The company is responsible for overseeing the business related to the processing of personal information and, for the resolution of complaints and remedies related to the processing of personal information, has designated a personal information protection manager as follows. If you have inquiries regarding personal information, please contact the following personal information protection manager/contact person. The company will promptly respond to and handle inquiries from the data subject.",
    "policy_sub10_table_tr1_td1": "<strong>Personal Information Protection Manager</strong><br />- Name: Lee, Kyungsoo<br />- Affiliation: Operations Support Team<br />- Position: Team Leader of Operations Support Team<br />- Email: skpinx_it@skpinx.co.kr<br />- Phone Number: 064-793-6070<br /><br />",
    "policy_sub10_table_tr2_td1": "<strong>Personal Information Protection Officer</strong><br />- Name: Oh, Ye Seul<br />- Affiliation: ESG TF<br />- Position: ESG TF Manager<br />- Email: yeseul12@sk.com<br/>- Phone Number: 064-793-5016<br/><br/>",
    "policy_sub11_desc1": "In accordance with Article 29 of the Personal Information Protection Act, the company has implemented the following technological, managerial, and physical measures to ensure security:",
    "policy_sub11_desc2": "1. Minimization of Personal Information Processing Staff",
    "policy_sub11_desc3": "To safeguard personal information, the company minimizes the authorization granted to personal information processors.",
    "policy_sub11_desc4": "2. Regular Training for Personal Information Processing Staff",
    "policy_sub11_desc5": "Regular training sessions are conducted to enhance awareness of personal information protection.",
    "policy_sub11_desc6": "3. Regular Internal Audits",
    "policy_sub11_desc7": "To ensure the security of personal information processing, regular internal audits are conducted periodically.",
    "policy_sub11_desc8": "4. Establishment and Implementation of Internal Management Plans",
    "policy_sub11_desc9": "For the secure processing and management of personal information, internal management plans are established and implemented.",
    "policy_sub11_desc10": "5. Encryption of Personal Information",
    "policy_sub11_desc11": "User's personal information and passwords are stored and managed in an encrypted form. Additionally, during transmission, separate security measures are employed to securely manage the information.",
    "policy_sub11_desc12": "6. Technical Measures Against Hacking and Similar Threats",
    "policy_sub11_desc13": "The company installs security programs and conducts regular updates and checks to prevent the leakage and damage of personal information due to hacking, computer viruses, etc. Additionally, it establishes systems in areas with controlled access from external sources, employing both technical and physical monitoring and blocking.",
    "policy_sub11_desc14": "7. Restriction of Access to Personal Information",
    "policy_sub11_desc15": "Access to the database systems processing personal information is controlled through the assignment, modification, and revocation of access permissions. Measures are implemented to control access to personal information, and intrusion prevention systems are used to regulate unauthorized access from external sources.",
    "policy_sub11_desc16": "8. Preservation and Prevention of Tampering of Access Records",
    "policy_sub11_desc17": "Access records for the personal information processing system are stored and managed for a minimum of one year. Security features are utilized to prevent tampering, theft, or loss of access records.",
    "policy_sub11_desc18": "9. Use of Locking Devices for Document Security",
    "policy_sub11_desc19": "Documents containing personal information and auxiliary storage media are stored in secure locations equipped with locking devices.",
    "policy_sub11_desc20": "10. Access Control for Unauthorized Individuals",
    "policy_sub11_desc21": "A separate physical storage location is designated for the storage of personal information, and access control procedures are established and implemented.",
    "policy_sub11_desc22": "11. The company disclaims any responsibility for issues arising from the leakage of personal information, such as ID and password, due to the data subject's negligence or problems on the internet.",
    "policy_sub11_desc23": "12. In the event of a personal information breach, leakage, or damage, the company promptly notifies the data subject and takes necessary measures to minimize the damage.",
    "policy_sub12_desc1": "The company operates and manages video information processing devices as outlined below:",
    "policy_sub12_1_desc1": "1. Basis and Purpose of Installation of Video Information Processing Devices",
    "policy_sub12_1_desc2": "The company installs and operates video information processing devices with the aim of ensuring the safety of customers/members (employees and affiliated personnel), protecting the company's assets, gathering evidence for crimes and various incidents, preventing vehicle theft and damage, securing the safety of facilities and users from disasters such as fires, and serving as substantiating evidence.",
    "policy_sub12_2_desc1": "2. CCTV information",
    "policy_sub12_2_table_tr1_td1": "Number of Installation Units",
    "policy_sub12_2_table_tr1_td2": "Installation Locations and Coverage",
    "policy_sub12_2_table_tr2_td1": "61 units",
    "policy_sub12_2_table_tr2_td2": "Golf course clubhouse building interior and exterior, golf course areas, and parking lot",
    "policy_sub12_2_table_tr3_td1": "8 units",
    "policy_sub12_2_table_tr3_td2": "Welfare facility building interior and exterior, and parking lot",
    "policy_sub12_2_table_tr4_td1": "25 units",
    "policy_sub12_2_table_tr4_td2": "Podo Hotel building interior and exterior, and parking lot",
    "policy_sub12_2_table_tr5_td1": "47 units",
    "policy_sub12_2_table_tr5_td2": "The Annex building interior and exterior, and parking lot",
    "policy_sub12_2_table_tr6_td1": "16 units",
    "policy_sub12_2_table_tr6_td2": "Community center building interior and exterior, and parking lot",
    "policy_sub12_3_desc1": "3. Overall Responsible Person and Operation Manager",
    "policy_sub12_3_desc2": "To protect video information and address complaints related to personal information, the company has appointed individuals for the protection of personal video information as follows:",
    "policy_sub12_3_table_tr1_td1": "Category",
    "policy_sub12_3_table_tr1_td2": "Name",
    "policy_sub12_3_table_tr1_td3": "Position",
    "policy_sub12_3_table_tr1_td4": "Affiliation",
    "policy_sub12_3_table_tr1_td5": "Contact No.",
    "policy_sub12_3_table_tr2_td1": "Overall Responsible Person",
    "policy_sub12_3_table_tr2_td2": "Kang, Changhyeop",
    "policy_sub12_3_table_tr2_td3": "Team leader",
    "policy_sub12_3_table_tr2_td4": "Infra support team",
    "policy_sub12_3_table_tr2_td5": "064-793-6060",
    "policy_sub12_3_table_tr3_td1": "Operation Manager",
    "policy_sub12_3_table_tr3_td2": "Shin, Changjin",
    "policy_sub12_3_table_tr3_td3": "Part leader",
    "policy_sub12_3_table_tr3_td4": "Infra 2nd part",
    "policy_sub12_3_table_tr3_td5": "064-793-6061",
    "policy_sub12_4_desc1": "4. Video Information Shooting Hours, Storage Period, Storage Location, Processing Method",
    "policy_sub12_4_table_tr1_td1": "Shooting Hours",
    "policy_sub12_4_table_tr1_td2": "Storage Period",
    "policy_sub12_4_table_tr1_td3": "Storage Location",
    "policy_sub12_4_table_tr2_td1": "24 hours",
    "policy_sub12_4_table_tr2_td2": "15 days from the date of shooting",
    "policy_sub12_4_table_tr2_td3": "Golf course clubhouse office, Welfare facility communication room,<br />Podo Hotel office, Community center surveillance room,",
    "policy_sub12_4_table_tr3_td2": "30 days from the date of shooting",
    "policy_sub12_4_table_tr3_td3": "The Annex disaster prevention room",
    "policy_sub12_4_desc2": "Processing Method: The company records and manages details regarding the unauthorized use, third-party disclosure, destruction, and inspection requests of personal video information. Upon expiration of the storage period, the information is permanently deleted using methods that make restoration impossible (shredding or incineration for printed materials).",
    "policy_sub12_5_desc1": "5. Details Regarding the Method and Location of Verifying Video Information",
    "policy_sub12_5_desc2": "- Verification Method: Contact the Personal Video Information Protection Manager in advance and visit our company for verification.",
    "policy_sub12_5_desc3": "- Verification Location: Infra Support Team",
    "policy_sub12_6_desc1": "6. Measures for Requests to Access Video Information from Data Subjects",
    "policy_sub12_6_desc2": "If you wish to access or confirm the existence of personal video information, or request its deletion, you may do so at any time. However, such requests are limited to personal video information in which you are captured and clearly necessary for the urgent protection of your life, body, property interests. The company will promptly review and take necessary actions upon requests for accessing, confirming, or deleting personal video information. In the event that a request is unavoidably denied, the reasons for such denial will be communicated to the requester.",
    "policy_sub12_7_desc1": "7. Measures to Ensure the Security of Video Information",
    "policy_sub12_7_desc2": "The company safeguards customer video information through security systems such as closed networks or firewalls. Additionally, to protect personal video information, access to such information is restricted to a minimum number of personnel, who undergo continuous training. To limit arbitrary handling of personal video information, the company records and manages details such as the purpose of access, the person accessing, and the date of access when viewing personal video information. Furthermore, for the secure physical storage of video information, locks are installed. Access is controlled by designating secure areas and restricting entry to authorized personnel with prior approval, ensuring a controlled and secure environment.",
    "policy_sub13_desc1": "To seek remedies for infringements on their rights due to personal information breaches, data subjects have the option to apply for dispute resolution or consultation with the Personal Information Dispute Resolution Committee, as well as the Korea Internet & Security Agency Personal Information Infringement Report Center. For reporting and consulting on other cases of personal information breaches, please contact the following agencies:",
    "policy_sub13_1_desc1": "1. Personal Information Infringement Report Center (Operated by the Korea Internet & Security Agency)",
    "policy_sub13_1_desc2": "- Responsibilities: Reporting and consultation on facts related to personal information infringement",
    "policy_sub13_1_desc3": "- Website: privacy.kisa.co.kr",
    "policy_sub13_1_desc4": "- Phone: 118 (without area code)",
    "policy_sub13_1_desc5": "- Address: Personal Information Infringement Report Center, (301-2, Bitgaram-dong) 3rd floor, 9 Jinheung-gil, Naju-si, Jeollanam-do 58324, Korea",
    "policy_sub13_2_desc1": "2. Personal Information Dispute Resolution Committee (Operated by the Korea Internet & Security Agency)",
    "policy_sub13_2_desc2": "- Responsibilities: Application for personal information dispute resolution, group dispute resolution (civil resolution)",
    "policy_sub13_2_desc3": "- Website: www.kopico.go.kr",
    "policy_sub13_2_desc4": "- Phone: 1833-6972 (without area code)",
    "policy_sub13_2_desc5": "- Address: 12th floor, Seoul Government Complex, 209 Sejong-daero, Jongno-gu, Seoul 03171 Korea",
    "policy_sub13_3_desc1": "3. Supreme Prosecutors' Office, Cyber Investigation Division",
    "policy_sub13_3_desc2": "- Phone: 1301 (without area code)",
    "policy_sub13_3_desc3": "- Website: www.spo.go.kr",
    "policy_sub13_4_desc1": "4. National Police Agency, Cyber Investigation Bureau",
    "policy_sub13_4_desc2": "- Phone: 182 (without area code)",
    "policy_sub13_4_desc3": "- Website: ecrm.cyber.go.kr",
    "policy_sub14_desc1": "The personal information processing policy was established on September 1, 2013. In the event of additions, deletions, or modifications to the content due to changes in laws, policies, or security technologies, the revised personal information processing policy will be announced, including the reasons and details, through the 'website.'",
    "policy_sub14_desc2": "- Initial Announcement Date: September 1, 2013",
    "policy_sub14_desc3": "- Modification Date: May 26, 2015",
    "policy_sub14_desc4": "- Modification Date: September 20, 2016",
    "policy_sub14_desc5": "- Modification Date: November 23, 2018",
    "policy_sub14_desc6": "- Modification Date: August 16, 2023",
    "policy_sub14_desc7": "- Effective Date: October 6, 2023"
  },
  "ja": {
  }
}
</i18n>
